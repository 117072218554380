import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    minHeight: "min(max(100vh, 600px), 750px)",

    display: "flex",
    flexDirection: "column",
    gap: "32px",
    fontFamily: "Philosopher",
    justifyContent: "center",

    background: "url(/assets/imgs/profile/profilebg3.png) no-repeat",
    backgroundSize: "auto 100%",
    backgroundPosition: "100%",

    maxWidth: "1920px",
    margin: "auto",
    boxSizing: "border-box",
  },

  generalInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  avatarContainer: {
    position: "relative",
    width: "315px",
    height: "472.5px",
  },

  avatarImg: {
    width: "315px",
    borderRadius: "16px",
    maxWidth: "315px",
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    position: "absolute",
    top: 0,
    left: 0,
  },

  avatarVideo: {
    width: "315px",
    height: "472px",
    maxWidth: "315px",
    display: "hidden",

    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,

    "& video": {
      borderRadius: "16px",
      objectFit: "cover",
      boxShadow:
        "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    },
  },

  playButton: {
    position: "absolute",
    right: 24,
    top: 24,
    width: "32px!important",
    height: "32px!important",

    "& span": {
      width: "100%",
    },
  },

  playButtonIcon: {
    width: "24px!important",
    height: "24px!important",
    opacity: "0",
  },

  avatar: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "32px",
    position: "relative",

    "& span": { width: "315px", borderRadius: "16px", maxWidth: "315px" },
  },

  name: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    color: theme.palette.text.primary,
    fontSize: "48px",
    position: "relative",
    height: "78px",
    lineHeight: "78px",
    textAlign: "center",

    "& span": {
      fontSize: "16px",
      textAlign: "center",
      color: theme.palette.text.secondary,
      position: "absolute",
      bottom: "-42px",
      left: "50%",
      translate: "-50% 0",
    },
  },

  nameContainer: {
    position: "relative",
  },

  ogBadge: {
    position: "absolute",
    width: "110px!important",
    boxShadow: "none!important",
    left: -48,
    top: 48,

    [theme.breakpoints.down("sm")]: {
      left: 4,
      top: 48,
    },
  },

  goldenPlus: {
    width: "16px!important",
    height: 16,
    position: "absolute",
    right: -16,
    top: 12,
  },

  left: {
    display: "flex",
    gap: "16px",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gap: "8px",
    },
  },

  countryInfo: {
    height: "32px",

    [theme.breakpoints.down("sm")]: {
      height: "24px",
    },
  },

  genderInfo: {
    width: 40,
    height: 40,
    padding: 8,
    boxSizing: "border-box",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.gray,

    "& img": {
      width: 24,
      height: 24,
    },
  },

  right: {
    display: "flex",
    gap: "32px",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      gap: "8px",
    },
  },

  logo: {
    width: "72px",
    height: "72px",
    borderRadius: "50%",
    border: "3px solid",
    borderColor: theme.palette.background.defaultBorder,

    [theme.breakpoints.down("md")]: {
      width: "48px",
      height: "48px",
    },
  },

  familySymbol: {
    width: 72,
    height: 72,
    padding: 8,
    borderRadius: 8,
    boxSizing: "border-box",
    objectFit: "contain",

    [theme.breakpoints.down("sm")]: {
      width: "48px",
      height: "48px",
    },
  },

  copyButton: {
    padding: "16px",
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    height: "40px",
    background: theme.palette.background.gray,
    border: "2px solid",
    borderColor: theme.palette.background.defaultBorder,
    borderRadius: "8px",
    fontSize: "14px",
    textTransform: "none",

    "&:hover": {
      backgroundColor: theme.palette.background.grayHover,
    },
  },

  linkIcon: {
    width: "20px",
    height: "20px",
  },

  container: {
    gap: "48px",
    display: "flex",

    width: 920,
    margin: "auto",
    marginBottom: 32,
    marginTop: 64,

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",

      marginBottom: 16,
      marginTop: 32,
    },
  },

  info: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",

    [theme.breakpoints.down("md")]: {
      gap: "8px",
      padding: 16,
    },
  },

  infoCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    backgroundColor: theme.palette.background.cardBgTransparent,
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    height: "100%",
    padding: "16px 48px",
    gap: "16px",
    justifyContent: "space-between",
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

    [theme.breakpoints.down("md")]: {
      padding: "16px 16px",
    },
  },

  infoTable: {
    display: "flex",
    flexDirection: "column",
  },

  infoTableColumn: {
    display: "flex",
    width: "100%",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,

    "& div:first-of-type": {
      width: "120px",
      color: theme.palette.text.primary,
      padding: "10px 8px",
      fontSize: "18px",
      fontWeight: 900,
      borderRight: "1px solid",
      borderColor: theme.palette.background.defaultBorder,

      [theme.breakpoints.down("md")]: {
        width: "90px",
        fontSize: "14px",
      },
    },

    "& div:last-of-type": {
      flexGrow: "1",
      color: theme.palette.text.secondary,
      padding: "10px 8px",
      fontWeight: 900,
      display: "flex",
      alignItems: "center",
    },

    "& button": {
      minWidth: "unset",
      height: "28px",
      margin: "8px 0",
      padding: "8px 0",
      borderRadius: "50%",
      borderBottom: "1px solid",
      borderColor: theme.palette.background.defaultBorder,
    },

    "& img": {
      width: 28,
      height: 28,
    },

    "& a": {
      color: theme.palette.text.linkColor,
      textDecoration: "none",
      maxWidth: 100,
      textOverflow: "ellipsis",
      textWrap: "nowrap",
      overflow: "hidden",

      [theme.breakpoints.down("sm")]: {
        width: "100",
        fontSize: "14px",
      },
    },
  },

  familyInfoTitleYellow: {
    color: `${theme.palette.text.purple} !important`,
  },

  familyInfoTitlePurple: {
    color: `${theme.palette.text.brightYellow} !important`,
  },

  familyInfoText: {
    color: theme.palette.text.primary,
    margin: 0,
  },

  statusDot: {
    width: 8,
    height: 8,
    backgroundColor: theme.palette.background.statusAlive,
    borderRadius: "50%",
    margin: "4px 0 4px 8px",
    boxShadow: "#0cac10 0px 0px 10px 2px",
  },

  balance: {
    display: "flex",
    borderRadius: "12px",
    padding: "16px",
    backgroundColor: theme.palette.background.textBg,
    color: theme.palette.text.primary,
    gap: "64px",
    width: "min-content",
  },

  token: {
    width: "16px",
    height: "16px",
  },

  tokenInfo: { display: "flex", gap: "8px" },
  tokenAmount: {},

  actionList: {
    display: "flex",
    gap: "16px",
    justifyContent: "space-between",

    [theme.breakpoints.down("md")]: {
      gap: "8px",
    },
  },

  action: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    fontSize: "12px",
    fontWeight: 900,
    color: theme.palette.text.secondary,

    "& img": {
      height: "40px",
    },

    "& div": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      color: theme.palette.text.secondary,
      fontSize: "11px",
    },
  },

  actionButtonIcon: {
    display: "flex",
    gap: "8px",
    alignItems: "center",

    "& img": {
      width: "24px",
      height: "24px",
    },

    "& div": {
      color: theme.palette.text.primary,
      lineHeight: "40px",
    },
  },

  actionButton: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    borderRadius: "12px",
    padding: "24px 8px",
    minWidth: "140px",
    maxWidth: "140px",
    height: "96px",
    fontSize: "14px",

    background: theme.palette.background.transparent,
    boxShadow:
      "inset 0 -5px 20px 0px rgb(0 0 0 / 25%), inset 5px 0 20px 0px rgb(0 0 0 / 25%), inset 0 5px 20px 0px rgb(0 0 0 / 25%), inset -5px 0 20px 0px rgb(0 0 0 / 25%)",

    "&:hover": {
      boxShadow:
        "inset 0 -5px 10px 0px rgb(0 0 0 / 25%), inset 5px 0 10px 0px rgb(0 0 0 / 25%), inset 0 5px 10px 0px rgb(0 0 0 / 25%), inset -5px 0 10px 0px rgb(0 0 0 / 25%)",
      background: theme.palette.background.transparent,
    },

    [theme.breakpoints.down("md")]: {
      padding: "24px 0px",
      minWidth: "100px",
      maxWidth: "100px",
      fontSize: "12px",
    },
  },

  actionIcon: {
    width: 24,
    height: 24,
  },

  popoverContent: {
    display: "flex",
    minWidth: "240px",
    flexDirection: "column",
    fontFamily: "Philosopher",
    gap: "16px",

    "& img": {
      width: 48,
      height: 48,
    },

    "&>div": {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      // gap: "8px",
      width: "100%",
    },

    "&>div:last-of-type>div": {
      display: "flex",
      color: theme.palette.text.grayColor,
      fontWeight: 900,
      padding: 4,
      width: "100%",
      justifyContent: "center",

      "& :first-of-type": {
        color: theme.palette.text.primary,
      },

      "&.selected": {
        backgroundColor: theme.palette.background.lightGray,
      },
    },

    "& div:last-of-type>div>div": {
      width: 140,
    },
  },

  popoverTitle: {
    fontSize: 32,
    color: theme.palette.text.primary,
  },
}));

export default styles;
