import config from "../config/config";
import { ItemCategoryInfo } from "../types/ItemCategoryInfo";

export const ALLEGIANCE_CREATE_CRATE_COST = 25;

export const PREMIUM_KEY_CLAIM_CYCLE = 60 * 60 * 24 * 30; // 30 days

export const MAX_PROFILE_DESCRIPTION_LEN = 2000;

export const AUTH_TOKEN_HEADER = "AUTH_";

export const ACTIVITY_TRACKING_TS_KEY = "activity_tracking_ts";

export const ACTIVITY_LIMIT_DURATION = 60;

export const FAMILY_PREMIUM_THRESHOLD = 50;

export const BUSINESS_CATEGORY_ID = 4;

export const MARKET_ITEM_LISTING_DURATION = [0.5, 3, 12, 72];

export const BULLET_AIRDROP_INTERVAL = 60 * 60 * 24 * 7; // 1 week

export const BULLET_AIRDROP_INTERVAL_TEMP = 60 * 60 * 3; // 3 hours

export const MAFIA_BUY_FEE = 4.7;

export const MAFIA_SELL_FEE = 16.6;

export const PROFILE_PRICE_IN_MAFIA = 2000;

export const FAMILY_PRICE_IN_MAFIA = 500000;

export const CRATE_PRICE = 19.99;

export const MAX_PROFILE_NAME_LEN = 14;

export const MAX_FAMILY_NAME_LEN = 20;

export const LOGO_WIDTH = 500;

export const LOGO_HEIGHT = 800;

export const BG_WIDTH = 1920;

export const BG_HEIGHT = 750;

// Distribution for top 5 families
export const VaultDistributionRatio = [30, 25, 20, 15, 10, 5, 5, 5];

// Payout for 3 bosses, DON, Consigliere, Capodecina
export const VAULT_PAYOUT_RATIO = [55, 25, 20];

export const CONFIRMATION_DELAY = 3000;

// Maximum invitation count per specific duration
export const INVITE_LIMIT_COUNT = 10;

// Invitation limit duration
export const INVITE_LIMIT_DURATION = 3600;

// Family Bank Yield amount
export const FAMILY_BANK_YIELD_PERCENT = 0.5;

// Document name for global chat
export const FIRESTORE_GLOBAL_CHAT_DOCUMENT = "global";

// Chat message count per page from firebase
export const CHAT_COUNT_PER_PAGE = 15;

export const NOTIFICATION_COUNT_PER_PAGE = 10;

export const ItemCategoryInfoList: ItemCategoryInfo[] = [
  {
    id: 0,
    name: "Cash",
    icon: "cashitem.png",
    values: [
      50000, 150000, 250000, 350000, 500000, 1000000, 2500000, 4000000, 5000000,
      10000000,
    ],
    icons: [],
    currency: "$",
    chances: [28, 18, 15, 12, 9, 7, 5, 3, 2, 1],
    inventoryWorth: [5, 15, 25, 35, 50, 100, 250, 400, 500, 1000],
    profileWorth: [2.5, 7.5, 12.5, 17.5, 25, 50, 125, 200, 250, 500],
  },

  {
    id: 1,
    name: "Bullet",
    icon: "bulletitem.png",
    icons: [],

    values: [250, 500, 750, 1500, 3500, 5000, 7000, 15000, 20000, 30000],
    chances: [28, 18, 15, 12, 9, 7, 5, 3, 2, 1],
    inventoryWorth: [25, 50, 75, 150, 350, 500, 700, 1500, 2000, 3000],
    profileWorth: [2.5, 5, 7.5, 15, 35, 50, 70, 150, 200, 300],
  },

  {
    id: 2,
    name: "Health",
    icon: "healthitem.png",
    icons: [],

    values: [50, 100, 150, 200, 250, 300, 350, 500, 750, 1000],
    chances: [28, 18, 15, 12, 9, 7, 5, 3, 2, 1],
    inventoryWorth: [100, 200, 300, 400, 500, 600, 700, 1000, 1500, 2000],
    profileWorth: [10, 20, 30, 40, 50, 60, 70, 100, 150, 200],
  },

  {
    id: 3,
    name: "ShopItem",
    icon: "shopitem.png",
    values: [
      "Hand Gun Colt",
      "Remington",
      "Thompson",
      "Molotov cocktail",
      "Grenade",
      "Motorcycle",
      "Bullet proof vest",
      "Bullet proof suit",
      "Armored car",
      "Douglas M-3",
    ],
    icons: [
      "shop-item-colt.png",
      "shop-item-remington.png",
      "shop-item-tommygun.png",
      "shop-item-molotov.png",
      "shop-item-grenade.png",
      "shop-item-rmotorcycle.png",
      "shop-item-vest.png",
      "shop-item-suit.png",
      "shop-item-amorcar.png",
      "shop-item-douglas.png",
    ],
    chances: [15, 15, 14, 12, 11, 10, 8, 7, 5, 3],
    inventoryWorth: [5, 15, 24, 35, 50, 100, 250, 500, 750, 850],
    profileWorth: [0.5, 1.5, 2.4, 3.5, 5, 10, 25, 50, 75, 85],
  },

  {
    id: 4,
    name: "Business",
    icon: "businessitem.png",
    icons: [
      "carcrusheritem.png",
      "shopitem-new.png",
      "bankitem.png",
      "hospitalitem.png",
      "detectiveagencyitem.png",
      "boozeitem.png",
      "narcoticsitem.png",
      "slotmachineitem.png",
      "rouletteitem.png",
      "bulletfactoryitem.png",
    ],
    values: [
      "Car crusher",
      "Shop owner",
      "Bank",
      "Hospital",
      "Detective Agency",
      "Booze warehouse",
      "Narcotics warehouse",
      "Slotmachine",
      "Roulette",
      "Bullet factory",
    ],

    chances: [17, 17, 16, 16, 12, 8, 6, 3, 3, 2],
    inventoryWorth: [
      1500, 5000, 3000, 5000, 2000, 8000, 8000, 9500, 11000, 15000,
    ],
    profileWorth: [150, 500, 300, 500, 200, 800, 800, 950, 1100, 1500],
  },

  {
    id: 5,
    name: "Bodyguards",
    icon: "bodyguarditem.png",
    values: [
      "Lvl 3 - Johnny",
      "Lvl 3 - Jim",
      "Lvl 3 - Sam",
      "Lvl 5 - Johnny",
      "Lvl 6 - Jim",
      "Lvl 6 - Sam",
      "Lvl 7 - Frank",
      "Lvl 8 - Johnny",
      "Lvl 10 - Sam",
      "Lvl 10 - Frank",
    ],
    icons: [
      "johnny-bgsmall.png",
      "jim-bgsmall.png",
      "sam-bgsmall.png",
      "johnny-bgsmall.png",
      "jim-bgsmall.png",
      "sam-bgsmall.png",
      "frank-bgsmall.png",
      "johnny-bgsmall.png",
      "sam-bgsmall.png",
      "frank-bgsmall.png",
    ],
    chances: [10, 10, 10, 20, 20, 10, 10, 5, 4, 1],
    inventoryWorth: [100, 150, 200, 300, 400, 600, 1000, 1500, 2000, 2500],
    profileWorth: [10, 15, 20, 30, 40, 60, 100, 150, 200, 250],
  },

  {
    id: 6,
    name: "Helper Credits",
    icon: "helperitem.png",
    icons: [],

    values: [100, 250, 500, 1000, 1500, 2000, 2500, 3000, 3500, 5000],
    chances: [10, 10, 10, 20, 20, 10, 10, 5, 4, 1],
    inventoryWorth: [10, 25, 50, 100, 150, 200, 250, 300, 350, 500],
    profileWorth: [1, 2.5, 5, 10, 15, 20, 25, 30, 35, 50],
  },

  {
    id: 7,
    name: "Car",
    icon: "carsitem.png",
    currency: "$",
    values: [
      5000, 15000, 35000, 75000, 100000, 150000, 250000, 350000, 450000, 500000,
    ],
    icons: [
      "car-item-5.png",
      "car-item-15.png",
      "car-item-35.png",
      "car-item-75.png",
      "car-item-100.png",
      "car-item-150.png",
      "car-item-250.png",
      "car-item-350.png",
      "car-item-450.png",
      "car-item-500.png",
    ],
    chances: [10, 10, 10, 20, 20, 10, 10, 5, 4, 1],
    inventoryWorth: [20, 50, 100, 150, 200, 250, 450, 500, 550, 600],
    profileWorth: [2, 5, 10, 15, 20, 25, 45, 50, 55, 60],
  },

  {
    id: 8,
    name: "Wrapped NFTs",
    icon: "key.png",
    values: ["Game Key", "Game OG"],
    icons: ["key.png", "OGNFTIMAGE.png"],
    chances: [10, 10],
    inventoryWorth: [150, 750],
    profileWorth: [15, 75],
  },

  {
    id: 9,
    name: "Keys",
    icon: "key.png",
    values: [
      "1 Game Key",
      "3 Game Keys",
      "5 Game Keys",
      "7 Game Keys",
      "10 Game Keys",
      "15 Game Keys",
      "25 Game Keys",
      "50 Game Keys",
      "75 Game Keys",
      "100 Game Keys",
    ],
    icons: [
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
    ],
    chances: [20, 20, 14, 12, 11, 10.5, 8, 3, 1, 0.5],
    inventoryWorth: [150, 450, 750, 1050, 1500, 2250, 3250, 7500, 11250, 15000],
    profileWorth: [15, 45, 75, 1050, 150, 225, 325, 750, 1125, 1500],
  },

  {
    id: 10,
    name: "MAFIA",
    icon: "logo.png",
    values: [
      10000, 15000, 20000, 30000, 50000, 100000, 250000, 500000, 1000000,
      5000000,
    ],
    icons: [],
    chances: [22, 20, 17, 14, 11, 10.5, 3.4, 1.5, 0.5, 0.1],
    inventoryWorth: [4.5, 6.75, 9, 13.5, 22.5, 45, 112.5, 225, 450, 2250],
    profileWorth: [0.45, 0.675, 0.9, 1.35, 2.25, 4.5, 11.25, 22.5, 45, 225],
  },

  {
    id: 11,
    name: "OG NFTs",
    icon: "OGNFTIMAGE.png",
    values: [
      "1 OG NFT",
      "1 OG NFT",
      "1 OG NFT",
      "1 OG NFT",
      "1 OG NFT",
      "1 OG NFT",
      "3 OG NFTs",
      "3 OG NFTs",
      "7 OG NFTs",
      "10 OG NFTs",
    ],
    icons: [
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
    ],
    chances: [22, 20, 17, 14, 11, 10.5, 3.4, 1.5, 0.5, 0.1],
    inventoryWorth: [4.5, 6.75, 9, 13.5, 22.5, 45, 112.5, 225, 450, 2250],
    profileWorth: [0.45, 0.675, 0.9, 1.35, 2.25, 4.5, 11.25, 22.5, 45, 225],
  },
];

export const WealthRange = [
  {
    from: 0,
    to: 25000,
    category: "Straydog",
  },

  {
    from: 25000,
    to: 100000,
    category: "Poor",
  },

  {
    from: 100000,
    to: 500000,
    category: "Nouveau Rich",
  },

  {
    from: 500000,
    to: 2000000,
    category: "Rich",
  },

  {
    from: 2000000,
    to: 5000000,
    category: "Very Rich",
  },

  {
    from: 5000000,
    to: 10000000,
    category: "Too rich to be true",
  },

  {
    from: 10000000,
    to: 30000000,
    category: "Filthy rich",
  },

  {
    from: 30000000,
    to: 1000000000000,
    category: "Richer than God",
  },
];

export const City = [
  "Chicago",
  "Detroit",
  "New York",
  "New Jersey",
  "Las Vegas",
  "Medellin",
  "Bogota",
  "Caracas",
  "Palermo",
  "Messina",
  "Napoli",
];

export const WealthCategory = [
  "Straydog",
  "Poor",
  "Nouveau Rich",
  "Rich",
  "Very rich",
  "Too rich to be true",
  "Filthy rich",
  "Richer than God",
];

export const RankCategory = [
  "Nobody",
  "Errand boy",
  "Pickpocket",
  "Thief",
  "Associate",
  "Soldier",
  "Mobster",
  "Swindler",
  "Made man",
  "Hitman",
  "Assassin",
  "Local Chief",
  "Chief",
  "Consigliere",
  "Capo bastone",
  "Don",
  "Godfather",
];

export const PromotionMoney = [
  ["Errand boy", 1000],
  ["Pickpocket", 5000],
  ["Thief", 10000],
  ["Associate", 25000],
  ["Soldier", 35000],
  ["Mobster", 45000],
  ["Swindler", 50000],
  ["Made man", 75000],
  ["Hitman", 100000],
  ["Assassin", 250000],
  ["Local Chief", 500000],
  ["Chief", 1000000],
  ["Consigliere", 2000000],
  ["Capo bastone", 5000000],
];

export const WalletErrorMsg = {
  Rejected: "Returned error: User rejected the request.",
  Indexing: "Returned error: transaction indexing is in progress",
};

export const NavBarPages = [
  {
    title: "Players",
    path: "/",
    icon: "player.png",
  },
  { title: "Families", path: "/family", icon: "family.png" },
  {
    title: "Crate",
    path: "/crate",
    icon: "crate.png",

    plsDisabled: false,
  },
  { title: "Market", path: "/market", icon: "market.png", new: true },
  { title: "The Vault", path: "/vault", icon: "vault.png" },
  {
    title: "Game Wiki",
    path: config.gitbookUrl,
    icon: "docs.png",
    blank: true,
  },
  {
    title: "Buy $MAFIA",
    path: "/buy",
    icon: "buy.png",
  },

  // {
  //   title: "The game",
  //   path: "/game",
  //   icon: "game.png",
  //   new: true,
  // },
  // {
  //   title: "Profiles",
  //   path: "/profiles",
  //   icon: "profiles.png",
  // },
];

export const shortCountryName = [
  [
    "Virgin Islands of the United States",
    "Saint Helena, Ascension and Tristan da Cunha",
  ],

  ["Virgin Islands", "Saint Helena"],
];
