import { Box, Button } from "@mui/material";
import { useMemo } from "react";
import Dot from "../Dot";

import ActiveListingItem from "../MarketMyListing/ActiveListingItem";
import ActiveListingLoadingSkeleton from "../MarketMyListing/ActiveListingLoadingSkeleton";

import { useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import { Market } from "../../constants/enum/market";
import useCurrentTime from "../../hook/useCurrentTime";
import useWallet from "../../hook/useWallet";
import { MarketListedItem } from "../../types/MarketListedItem";

import useStyles from "./ActiveItemListing.styles";
import clsx from "clsx";

interface ActiveItemListingProps {
  setActionItem: React.Dispatch<
    React.SetStateAction<MarketListedItem | undefined>
  >;
  setShowingBids: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenMarketAuctionPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchasingType: React.Dispatch<
    React.SetStateAction<Market.ListType | undefined>
  >;
  setOpenTokenListPopup: React.Dispatch<React.SetStateAction<boolean>>;

  handleCancelListing: (item: MarketListedItem) => void;
  handleCompleteAuction: (item: MarketListedItem) => void;
}

const ActiveItemListing: React.FC<ActiveItemListingProps> = ({
  setActionItem,
  setShowingBids,
  setOpenMarketAuctionPopup,
  setOpenTokenListPopup,
  setPurchasingType,
  handleCancelListing,
  handleCompleteAuction,
}) => {
  const { classes } = useStyles();
  const currentTime = useCurrentTime();
  const { account } = useWallet();

  const { itemDetail, isLoadingItemDetail } = useAppSelector(
    (state) => state.market
  );

  const parsedItem = useMemo(() => {
    return itemDetail.activeItems.map((item) => {
      return {
        ...item,
        currency:
          item.listingInfo.token === config.mafiaAddress
            ? "MAFIA"
            : config.chainSymbol,
      };
    });
  }, [itemDetail.activeItems]);

  const renderActionButton = (item: (typeof itemDetail.activeItems)[0]) => {
    const isSeller =
      account.toLowerCase() === item.listingInfo.seller.toLowerCase();

    const isExpired = item.listingInfo.expiresAt <= new Date().getTime() / 1000;

    if (isSeller) {
      if (isExpired) {
        if (item.listingInfo.listingType === Market.ListType.AUCTION) {
          return item.listingInfo.currentPrice !==
            item.listingInfo.startingPrice ? (
            <Button
              className={classes.buyButton}
              onClick={() => {
                setActionItem(item);
                handleCompleteAuction(item);
              }}
            >
              Complete
            </Button>
          ) : (
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        } else {
          return (
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        }
      } else {
        if (item.listingInfo.listingType === Market.ListType.AUCTION) {
          return (
            <Button
              className={clsx(classes.cancelButton, classes.viewBidsButton)}
              onClick={(event) => {
                event.stopPropagation(); // Prevent parent onClick from firing
                setActionItem(item);
                setShowingBids(true);
                setOpenMarketAuctionPopup(true);
              }}
            >
              View Bids
            </Button>
          );
        } else {
          return <Box className={classes.listingStatusNormal}>Listed</Box>;
        }
      }
    } else {
      if (isExpired) {
        if (item.listingInfo.listingType === Market.ListType.AUCTION) {
          return item.listingInfo.currentPrice !==
            item.listingInfo.startingPrice ? (
            <Button
              className={classes.buyButton}
              onClick={() => {
                setActionItem(item);
                handleCompleteAuction(item);
              }}
            >
              Complete
            </Button>
          ) : (
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        } else {
          return (
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        }
      } else {
        return item.listingInfo.listingType === Market.ListType.FIXED ? (
          <Button
            className={classes.buyButton}
            onClick={(event) => {
              event.stopPropagation(); // Prevent parent onClick from firing
              setActionItem(item);
              setPurchasingType(Market.ListType.FIXED);
              setOpenTokenListPopup(true);
            }}
          >
            Buy Item
          </Button>
        ) : (
          <Button
            className={classes.bidButton}
            onClick={(event) => {
              event.stopPropagation(); // Prevent parent onClick from firing
              setActionItem(item);
              setShowingBids(false);
              setPurchasingType(Market.ListType.AUCTION);
              setOpenMarketAuctionPopup(true);
            }}
          >
            Make bid
          </Button>
        );
      }
    }
  };

  return (
    <Box className={classes.itemListing}>
      <Box className={classes.listingHeader}>
        <Dot width={12} color="red" isShadow={true} />
        <Box>Active Listing</Box>
      </Box>

      <Box className={classes.listingPanel}>
        <Box className={classes.listingPanelHeader}>
          <Box>Item</Box>
          <Box>Type</Box>
          <Box>Price</Box>
          <Box>Payout</Box>
          <Box>Expires/Action</Box>
        </Box>

        {isLoadingItemDetail
          ? [...new Array(7)].map((item, index) => {
              return <ActiveListingLoadingSkeleton key={index} />;
            })
          : parsedItem.map((item, index) => {
              return (
                <ActiveListingItem
                  item={item}
                  currentTime={currentTime}
                  renderActionButton={renderActionButton}
                  key={index}
                />
              );
            })}
      </Box>
    </Box>
  );
};

export default ActiveItemListing;
