import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "32px",
    boxSizing: "border-box",

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },
  body: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    maxWidth: "1200px",
    width: "100%",
    borderRadius: 16,
    backgroundColor: theme.palette.background.cardBg,
    border: "1px solid",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
    display: "flex",
    flexDirection: "column",
    gap: 24,
    padding: "32px 48px",

    [theme.breakpoints.down("md")]: {
      marginTop: "32px",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px",
      boxSizing: "border-box",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "16px",
      alignItems: "flex-end",
      padding: "8px 16px",
    },
  },
  title: {
    display: "flex",
    gap: "24px",
    alignItems: "end",
    "&>div:nth-of-type(1)": {
      fontSize: "30px",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "25px",
      },
    },
    "&>div:nth-of-type(2)": {
      fontSize: "17px",
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      width: "100%",
    },
  },
  searchFieldSection: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    minWidth: "200px",
    backgroundColor: theme.palette.background.lightGray,
    borderRadius: "8px",
    padding: "8px 24px",
    justifyContent: "space-between",

    "&>svg": {
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "17px",
      },
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      boxSizing: "border-box",
      padding: "8px 16px",
    },
  },
  searchField: {
    "& .MuiInputBase-input": {
      color: "white",
      fontFamily: "philosopher",
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
    },
    "& fieldset": {
      border: "none",
    },
  },
  tableContainer: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
      overflowX: "auto",
    },
  },
  tableBody: {
    [theme.breakpoints.down("sm")]: {},
  },
  tableHeader: {
    color: "white",
    display: "grid",
    fontSize: "17px",
    padding: "0 0 0 16px",
    gridTemplateColumns: "1fr 1fr 2.5fr 1fr 2fr 3fr 1fr 1fr 2fr",
    [theme.breakpoints.down("md")]: {
      minWidth: 1200,
    },
  },

  tableDetailsBody: {
    marginTop: "8px",
  },

  detail: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 2.5fr 1fr 2fr 3fr 1fr 1fr 2fr",
    alignItems: "center",
    color: "#474747",
    padding: "12px 0 12px 16px",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.darkBorder,
    transitionDuration: "300ms",

    [theme.breakpoints.down("md")]: {
      minWidth: 1200,
    },

    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },

    "& a": {
      textDecoration: "none",
      color: theme.palette.text.linkColor,
    },

    "& img": {
      borderRadius: 4,
      width: 24,
      height: 32,
    },

    "&:hover": {
      backgroundColor: theme.palette.background.whiteTransparent1,
    },
  },

  gender: {
    width: 24,
    height: "24px!important",
  },

  familyLogo: {
    "& img": {
      width: 24,
      objectFit: "cover",
    },
  },

  vaultAmount: {
    width: 100,
  },

  vaultIcon: {
    width: "16px!important",
    height: "16px!important",
  },

  pagination: {
    width: "100%",
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
    "& .MuiPaginationItem-root": {
      borderColor: theme.palette.background.gray,
      color: theme.palette.text.secondary,
      [theme.breakpoints.down("sm")]: {
        minWidth: "20px",
        padding: 0,
      },
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: theme.palette.background.gray,
      color: "white",
    },
    "& .MuiButtonBase-root": {
      [theme.breakpoints.down("sm")]: {
        minWidth: "25px",
        width: "25px",
        height: "25px",
        fontSize: "12px",
      },
    },
  },

  goldenText: {
    background: theme.palette.text.goldenGradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bold",
  },

  goldenPlus: {
    width: "10px!important",
    height: "100%",
    objectFit: "contain",
    objectPosition: "top",
  },
}));

export default styles;
