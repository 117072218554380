import { Box, Button } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import InventoryItemActionPopup from "../../components/InventoryItemActionPopup";
import ProfileCard from "../../components/ProfileCard";
import ProfileDescription from "../../components/ProfileDescription";
import ProfileInventory from "../../components/ProfileInventory";
import ProfileSetting from "../../components/ProfileSetting";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getCrateBalance,
  getInventoryItem,
  getOgNFTBalance,
  getUserPremiumInfo,
} from "../../reducers/profile.slice";
import { ItemInfo } from "../../types/ItemInfo";

import useWallet from "../../hook/useWallet";
import useStyles from "./index.styles";

const Profile = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { account } = useWallet();
  const { profile, myProfile, crateBalance, ogNFTBalance, premiumInfo } =
    useAppSelector((state) => state.profile);

  const [openCrateOpeningPopup, setOpenCrateOpeningPopup] = useState(false);
  const [transferItemInfo, setTransferItemInfo] = useState<ItemInfo>({
    categoryId: -2,
    cityId: -1,
    typeId: 0,
    itemId: 0,
  });

  const handleUpgradeClick = () => {
    if (ogNFTBalance > 0) {
      setOpenCrateOpeningPopup(true);
    } else {
      navigate(`/market`);
    }
  };

  useEffect(() => {
    if (profile.id) dispatch(getInventoryItem({ userId: profile.id }));
  }, [profile.id, dispatch, account]);

  useEffect(() => {
    if (profile.address) {
      dispatch(getCrateBalance({ account: profile.address }));
      dispatch(getOgNFTBalance({ account: profile.address }));
      dispatch(getUserPremiumInfo({ account: profile.address }));
    }
  }, [profile.address, dispatch]);

  return (
    <Box>
      <ProfileCard isPrivateBullet={profile.name !== myProfile.name} />

      {!profile || !myProfile || profile.name !== myProfile.name ? (
        <Box className={classes.row}>
          {profile.description && <ProfileDescription profile={profile} />}
        </Box>
      ) : (
        <Box className={clsx(classes.row, classes.third)}>
          <ProfileDescription profile={profile} editable />

          <ProfileSetting profile={profile} premiumInfo={premiumInfo} />

          {profile.premium !== 1 && (
            <Button
              className={classes.upgradeButton}
              onClick={handleUpgradeClick}
            >
              Upgrade to Player+
            </Button>
          )}
        </Box>
      )}

      <Box className={classes.row}>
        <ProfileInventory
          inventoryType="Items"
          small
          profileView
          isPrivate={
            (profile.name !== myProfile.name &&
              profile.inventoryVisibility === 2) ||
            (profile.family !== myProfile.family &&
              profile.inventoryVisibility === 1)
          }
        />
        <ProfileInventory
          inventoryType="NFTs"
          crateBalance={crateBalance}
          ogNFTBalance={ogNFTBalance}
          profileView
          small
        />
      </Box>

      <InventoryItemActionPopup
        itemInfo={transferItemInfo}
        openItemTransferPopup={openCrateOpeningPopup}
        setOpenCrateOpeningPopup={setOpenCrateOpeningPopup}
      />
    </Box>
  );
};

export default Profile;
