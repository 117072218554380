import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { ethers } from "ethers";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

import CustomDropDown from "../CustomDropdown/CustomDropDown";
import ListingItemDropDown from "../CustomDropdown/ListingItemDropDown";
import InventoryItemIcon from "../InventoryItemIcon";
import Logo from "../Logo";
import NativeIcon from "../NativeIcon";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useWallet from "../../hook/useWallet";

import config, { SERVER_URL } from "../../config/config";
import {
  CONFIRMATION_DELAY,
  ItemCategoryInfoList,
  MARKET_ITEM_LISTING_DURATION,
} from "../../constants/const";
import { Market } from "../../constants/enum/market";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import { createListingAction } from "../../reducers/market.slice";
import { getMyInventoryItems } from "../../reducers/profile.slice";
import { ItemInfo } from "../../types/ItemInfo";

import auctionIcon from "../../assets/imgs/marketplace/auction.png";
import priceTagIcon from "../../assets/imgs/marketplace/price-tag.png";

import { dispatchTxAction } from "../../helper/dispatchTxAction";
import { toastError, toastSuccess, toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";

const listingDurations = MARKET_ITEM_LISTING_DURATION.map((duration) =>
  duration < 1 ? `${duration * 60} mins` : `${duration} hours`
);

interface MarketListItemPopupProps {
  openMarketListItemPopup: boolean;
  setOpenMarketListItemPopup: React.Dispatch<React.SetStateAction<boolean>>;
  fetchListedItems: () => void;
}

const MarketListItemPopup: React.FC<MarketListItemPopupProps> = ({
  openMarketListItemPopup,
  setOpenMarketListItemPopup,
  fetchListedItems,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { account } = useWallet();

  const { myProfile, myInventoryItems } = useAppSelector(
    (state) => state.profile
  );

  const handleClose = () => {
    navigate(location.pathname);
    setOpenMarketListItemPopup(false);
  };

  const [itemToList, setItemToList] = useState("");
  const [itemInfoToList, setItemInfoToList] = useState<ItemInfo>();
  const [itemListDuration, setItemListDuration] = useState("");
  const [itemListType, setItemListType] = useState(Market.ListType.FIXED);
  const [itemPayoutCurrency, setItemPayoutCurrency] = useState(
    config.chainSymbol
  );
  const [usdAmount, setUsdAmount] = useState<string>("");
  const [latestSoldPrices, setLatestSoldPrices] = useState<number[]>([]);

  const itemList = useMemo(() => {
    return [...myInventoryItems].sort(
      (a, b) =>
        ItemCategoryInfoList[b.categoryId].inventoryWorth[b.typeId] -
          ItemCategoryInfoList[a.categoryId].inventoryWorth[a.typeId] ||
        a.categoryId - b.categoryId
    );
  }, [myInventoryItems]);

  const handleListItemChange = (event: SelectChangeEvent) => {
    const selectedItemId = event.target.value;

    const itemInfo = myInventoryItems.find(
      (item, index) => item.itemId === parseFloat(selectedItemId)
    );

    setItemInfoToList(itemInfo);
    setItemToList(selectedItemId);
  };

  const handleListDurationChange = (event: SelectChangeEvent) => {
    setItemListDuration(event.target.value);
  };

  const handleUsdAmountChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (value === "" || /^(\d+(\.\d{0,5})?|\.?\d{1,2})$/.test(value)) {
      setUsdAmount(value);
    }
  };

  const handleCreateListing = () => {
    if (!myProfile.address || !myProfile.id) {
      toastError(Errors.GLOBAL.PROFILE.NOT_CREATED);
      return;
    }

    if (!itemInfoToList) {
      toastError(Errors.MARKET.BASIC.NOT_SELECTED_ITEM);
      return;
    }

    if (!usdAmount) {
      toastError(Errors.MARKET.BASIC.USD_INVALID);
      return;
    }

    if (!itemListDuration) {
      toastError(Errors.MARKET.BASIC.DURATION_INVALID);
      return;
    }

    const actionParams = {
      itemId: itemInfoToList.itemId,
      startingPrice: parseFloat(usdAmount),
      listingType: itemListType === Market.ListType.FIXED ? 0 : 1,
      listingToken:
        itemPayoutCurrency === config.chainSymbol
          ? ethers.constants.AddressZero
          : config.mafiaAddress,

      duration:
        MARKET_ITEM_LISTING_DURATION[
          listingDurations.indexOf(itemListDuration)
        ] * 3600,

      account: account,
    };

    dispatchTxAction(
      dispatch,
      createListingAction(actionParams),
      () => {
        toastSuccess(Messages.MARKET.BASIC.LISTED_SUCCESS);
        handleClose();
        if (myProfile.id)
          dispatch(getMyInventoryItems({ userId: myProfile.id }));
      },
      CONFIRMATION_DELAY
    );
  };

  useEffect(() => {
    if (myProfile.id) dispatch(getMyInventoryItems({ userId: myProfile.id }));
  }, [dispatch, myProfile.id]);

  useEffect(() => {
    if (!itemInfoToList) return;

    const fetchLatestPrices = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/market/latestSold?categoryId=${itemInfoToList?.categoryId}&typeId=${itemInfoToList?.typeId}`
        );

        const latestSold = response.data.map((item: any, index: number) => {
          return item.currentPrice;
        });

        setLatestSoldPrices(latestSold);
      } catch (error) {
        console.log("Failed to fetch latest price history:", error);
        setLatestSoldPrices([]);
      }
    };

    fetchLatestPrices();
  }, [itemInfoToList]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const itemId = parseInt(queryParams.get("listItem") || "0");
    if (itemId > 0) {
      const itemInfo = myInventoryItems.find(
        (item, index) => item.itemId === itemId
      );

      setItemInfoToList(itemInfo);
      setItemToList(itemId.toString());
    }
  }, [location, myInventoryItems, setOpenMarketListItemPopup]);

  return (
    <Dialog
      open={openMarketListItemPopup}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box>List your item</Box>

          <Box className={classes.listOption}>
            <InventoryItemIcon
              categoryId={itemInfoToList?.categoryId || 0}
              typeId={itemInfoToList?.typeId || 0}
              className={classes.itemIcon}
            />

            <ListingItemDropDown
              placeholder="Select Item"
              sx={{
                padding: "8px 8px",
                backgroundColor: "#363a3b!important",
                border: "1px solid #23272a",
                width: 180,
              }}
              value={itemToList}
              items={itemList || []}
              handleChange={handleListItemChange}
            />
          </Box>

          <Box className={classes.listOption}>
            <Box>Listing type:</Box>
            <Box className={classes.switch}>
              <Box
                className={clsx(
                  classes.option,
                  itemListType === Market.ListType.FIXED
                    ? classes.optionSelected
                    : ""
                )}
                onClick={() => {
                  setItemListType(Market.ListType.FIXED);
                }}
              >
                <Box
                  component="img"
                  src={priceTagIcon}
                  className={classes.switchOptionIcon}
                ></Box>
                <Box>Fixed</Box>
              </Box>

              <Box
                className={clsx(
                  classes.option,
                  itemListType === Market.ListType.AUCTION
                    ? classes.optionSelected
                    : ""
                )}
                onClick={() => {
                  setItemListType(Market.ListType.AUCTION);
                }}
              >
                <Box
                  component="img"
                  src={auctionIcon}
                  className={classes.switchOptionIcon}
                ></Box>
                <Box>Auction</Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.listOption}>
            <Box>
              <Box>
                {itemListType === Market.ListType.FIXED
                  ? "Price:"
                  : "Starting Price:"}
              </Box>

              {latestSoldPrices.length > 0 && (
                <Tooltip
                  title={
                    <Box>
                      {latestSoldPrices.map((price, index) => {
                        return <Box key={index}>{toUSDFormat(price)} USD</Box>;
                      })}
                    </Box>
                  }
                >
                  <Box className={classes.listOptionLabelSmall}>
                    &gt; Check latest sold
                  </Box>
                </Tooltip>
              )}
            </Box>

            <Box className={classes.input}>
              <TextField
                placeholder="Input $USD Amount"
                variant="standard"
                className={classes.inputBox}
                InputProps={{
                  disableUnderline: true, // Disable the underline
                }}
                value={usdAmount}
                onChange={handleUsdAmountChange}
                autoComplete="off"
                sx={{
                  input: {
                    "&::placeholder": {
                      opacity: 1,
                    },
                  },
                }}
              ></TextField>
              <MonetizationOnOutlinedIcon />
            </Box>
          </Box>

          <Box className={classes.listOption}>
            <Box>Select Payout:</Box>
            <Box className={classes.switch}>
              <Box
                className={clsx(
                  classes.option,
                  itemPayoutCurrency === config.chainSymbol
                    ? classes.optionSelected
                    : ""
                )}
                onClick={() => {
                  setItemPayoutCurrency(config.chainSymbol);
                }}
              >
                <NativeIcon classNames={classes.switchOptionIconSymbol} />
                <Box className={classes.symbolName}>{config.chainSymbol}</Box>
                <Box className={classes.feeInfo}>20% fee</Box>
              </Box>

              <Box
                className={clsx(
                  classes.option,
                  itemPayoutCurrency === config.symbol
                    ? classes.optionSelected
                    : ""
                )}
                onClick={() => {
                  setItemPayoutCurrency(config.symbol);
                }}
              >
                <Logo classNames={classes.switchOptionIconSymbol} />
                <Box className={classes.symbolName}>MAFIA</Box>
                <Box className={classes.feeInfo}>0% fee</Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.listOption}>
            <Box>Expires:</Box>
            <CustomDropDown
              placeholder="Select duration"
              sx={{
                padding: "8px 8px",
                backgroundColor: "#363a3b!important",
                border: "1px solid #23272a",
                width: 180,
              }}
              value={itemListDuration}
              items={listingDurations}
              handleChange={handleListDurationChange}
            />
          </Box>

          <Box className={classes.confirmSection}>
            <Button onClick={handleCreateListing}>Confirm Listing</Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MarketListItemPopup;
