import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config, { SERVER_URL } from "../config/config";
import {
  ACTIVITY_TRACKING_TS_KEY,
  AUTH_TOKEN_HEADER,
} from "../constants/const";
import { signMessage } from "../helper/contractFunctions";

export interface AuthState {
  isAuthorized: boolean;
  account: string;
  signMsg: string;
  signature: string;
  expireAt: number;

  rateExceeded: boolean;

  activityTimestamps: number[];
}

const initialState: AuthState = {
  isAuthorized: false,
  signMsg: "",
  signature: "",
  account: "",
  expireAt: 0,

  rateExceeded: false,
  activityTimestamps: [],
};

export const requestAuthorize = createAsyncThunk(
  "auth/requestAuthorize",
  async ({ account }: { account: string }) => {
    const expireAt = Math.floor(new Date().getTime() / 1000) + 60 * 60 * 24 * 3;
    const signMsg = `Sign in ${config.domain} with ${account} - expire at ${expireAt}`;

    const signature = await signMessage(signMsg);

    return { account, signature, expireAt, signMsg };
  }
);

export const activityTracking = createAsyncThunk(
  "auth/tracking",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${SERVER_URL}/activity`);
      return response.data;
    } catch (err: any) {
      console.log(err);
      return rejectWithValue(err.response.status);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: (state) => {
      state.isAuthorized = false;
      state.signature = "";
      state.account = "";
      state.expireAt = 0;
      state.signMsg = "";
    },
    setAuth: (state, { payload }) => {
      const storedToken = localStorage.getItem(
        `${AUTH_TOKEN_HEADER}${payload.toLowerCase()}`
      );
      const storedSignature = storedToken?.split("_")[0];
      const expireAt = parseInt(storedToken?.split("_")[1] || "0");
      const signMsg = `Sign in ${config.domain} with ${payload} - expire at ${expireAt}`;

      state.isAuthorized = true;
      state.signature = storedSignature || "";
      state.account = payload;
      state.expireAt = expireAt;
      state.signMsg = signMsg;
    },

    setRateLimitExceeded: (state, { payload }) => {
      state.rateExceeded = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestAuthorize.pending, () => {});
    builder.addCase(requestAuthorize.fulfilled, (state, { payload }) => {
      state.isAuthorized = true;
      state.signature = payload.signature;
      state.account = payload.account;
      state.expireAt = payload.expireAt;
      state.signMsg = payload.signMsg;

      localStorage.setItem(
        `${AUTH_TOKEN_HEADER}${payload.account}`,
        `${payload.signature}_${payload.expireAt}`
      );
    });
    builder.addCase(requestAuthorize.rejected, (state, { error }) => {});

    builder.addCase(activityTracking.pending, (state) => {
      if (state.activityTimestamps.length === 0) {
        const activityTimestamps = localStorage.getItem(
          ACTIVITY_TRACKING_TS_KEY
        );

        state.activityTimestamps =
          activityTimestamps?.split(",").map((ts, index) => parseInt(ts)) || [];
      }
    });
    builder.addCase(activityTracking.fulfilled, (state, { payload }) => {
      state.rateExceeded = false;

      state.activityTimestamps.push(Math.floor(new Date().getTime() / 1000));

      if (state.activityTimestamps.length > 10) {
        const newActivityTimestamps = state.activityTimestamps.slice(
          1,
          state.activityTimestamps.length
        );
        state.activityTimestamps = newActivityTimestamps;
      }
      localStorage.setItem(
        ACTIVITY_TRACKING_TS_KEY,
        state.activityTimestamps.join(",")
      );
    });
    builder.addCase(activityTracking.rejected, (state, { payload }) => {
      if (payload === 429) {
        console.log("Too many requests");

        state.rateExceeded = true;
      }
    });
  },
});

export const { clearAuth, setAuth, setRateLimitExceeded } = authSlice.actions;

export default authSlice.reducer;
