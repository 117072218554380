import { useEffect, useState } from "react";
import Web3 from "web3";
import config from "../config/config";

const web3 = new Web3(config.providerList[0]);

const useVaultBalance = () => {
  const [vault, setVault] = useState(0);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        // Get balance returns the value in wei
        const balanceWei = await web3.eth.getBalance(config.vaultAddress);
        // Convert wei to ETH
        const balance = web3.utils.fromWei(balanceWei, "ether");

        setVault(parseFloat(Number(balance).toFixed(2)));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchBalance();
  }, []);

  return vault;
};

export default useVaultBalance;
