import AddIcon from "@mui/icons-material/Add";

import { Box, Button, SelectChangeEvent, Skeleton } from "@mui/material";
import clsx from "clsx";
import millify from "millify";
import { useEffect, useMemo, useState } from "react";

import docIcon from "../../assets/imgs/marketplace/docs.png";
import filterIcon from "../../assets/imgs/marketplace/filter.png";
import ItemFilterDropDown from "../CustomDropdown/ItemFilterDropDown";

import { ItemCategoryInfoList } from "../../constants/const";

import { useAppSelector } from "../../app/hooks";
import config from "../../config/config";

import useWallet from "../../hook/useWallet";
import useStyles from "./index.styles";
import { useLocation, useNavigate } from "react-router-dom";

interface MarketHeaderProps {
  setOpenMarketRulesPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenMarketListItemPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setItemFilter: React.Dispatch<React.SetStateAction<number[]>>;
}

const MarketHeader: React.FC<MarketHeaderProps> = ({
  setOpenMarketRulesPopup,
  setOpenMarketListItemPopup,
  setItemFilter,
}) => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { account, connectWallet } = useWallet();

  const { totalUsd, totalFee, itemSold, isLoadingMarketStatus } =
    useAppSelector((state) => state.market);

  const [selectedItemCategory, setSelectedItemCategory] = useState<string[]>(
    []
  );

  const [openFilterDropdown, setOpenFilterDropDown] = useState(false);

  const itemCategories = useMemo(() => {
    return ItemCategoryInfoList.map((category) => category.name);
  }, []);

  const selectedItemCategoryId = useMemo(() => {
    return selectedItemCategory.map((category, index) =>
      itemCategories.indexOf(category)
    );
  }, [itemCategories, selectedItemCategory]);

  const handleSelectedCategoryChanged = (
    event: SelectChangeEvent<typeof selectedItemCategory>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedItemCategory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    setItemFilter(selectedItemCategoryId);
  }, [setItemFilter, selectedItemCategoryId]);

  const isAtItemList =
    location.pathname === "/market/itemList" || location.pathname === "/market";
  const isAtHistory = location.pathname === "/market/history";
  const isAtMyListing = location.pathname === "/market/myListings";

  return (
    <Box className={classes.pageHeader}>
      <Box>MarketPlace</Box>

      <Box className={classes.marketStatus}>
        <Box className={classes.statusField}>
          <Box>Total USD</Box>

          {isLoadingMarketStatus ? (
            <Skeleton
              width={64}
              height={20}
              variant="rounded"
              animation="wave"
              sx={{ bgcolor: "#ffffff1a" }}
            />
          ) : (
            <Box>${millify(totalUsd)}</Box>
          )}
        </Box>

        {isLoadingMarketStatus ? (
          <Box className={classes.statusField}>
            <Box>Items Sold</Box>
            <Skeleton
              width={64}
              height={20}
              variant="rounded"
              animation="wave"
              sx={{ bgcolor: "#ffffff1a" }}
            />
          </Box>
        ) : itemSold ? (
          <Box className={classes.statusField}>
            <Box>Items Sold</Box>
            <Box>{itemSold}</Box>
          </Box>
        ) : (
          ""
        )}

        <Box className={classes.statusField}>
          <Box>Total fees</Box>

          {isLoadingMarketStatus ? (
            <Skeleton
              width={64}
              height={20}
              variant="rounded"
              animation="wave"
              sx={{ bgcolor: "#ffffff1a" }}
            />
          ) : (
            <Box>
              {millify(totalFee)} {config.chainSymbol}
            </Box>
          )}
        </Box>
      </Box>

      <Box className={classes.actionList}>
        <Button
          className={
            isAtItemList
              ? clsx(classes.grayButton, classes.grayButtonSelected)
              : classes.grayButton
          }
          startIcon={<Box component="img" src={docIcon}></Box>}
          onClick={() => {
            navigate(`/market/itemList`);
          }}
        >
          Market
        </Button>

        <Button
          className={
            isAtHistory
              ? clsx(classes.grayButton, classes.grayButtonSelected)
              : classes.grayButton
          }
          startIcon={<Box component="img" src={docIcon}></Box>}
          onClick={() => {
            navigate(`/market/history`);
          }}
        >
          History
        </Button>

        <Button
          className={classes.grayButton}
          startIcon={<Box component="img" src={docIcon}></Box>}
          onClick={() => {
            setOpenMarketRulesPopup(true);
          }}
        >
          Market rules
        </Button>

        <Box
          sx={{
            display: "flex",
            position: "relative",
          }}
          className={classes.filter}
        >
          <ItemFilterDropDown
            open={openFilterDropdown}
            setOpen={setOpenFilterDropDown}
            sx={{
              backgroundColor: "#171a1bee!important",
              border: "1px solid #23272a",
              borderRadius: "16px!important",
              color: "white",
              display: "none",
              position: "absolute",
              width: "145px",
              height: "42px",
              opacity: "0",
            }}
            value={selectedItemCategory}
            items={itemCategories}
            handleChange={handleSelectedCategoryChanged}
          />

          <Button
            className={classes.grayButton}
            startIcon={<Box component="img" src={filterIcon}></Box>}
            onClick={() => {
              setOpenFilterDropDown(true);
            }}
          >
            Item Filter
          </Button>
        </Box>

        <Button
          className={
            isAtMyListing
              ? clsx(classes.grayButton, classes.grayButtonSelected)
              : classes.grayButton
          }
          startIcon={<AddIcon />}
          onClick={() => {
            navigate(`/market/myListings`);
          }}
        >
          My Listings
        </Button>

        <Button
          className={classes.listItemButton}
          onClick={() => {
            account ? setOpenMarketListItemPopup(true) : connectWallet();
          }}
        >
          List Item
        </Button>
      </Box>
    </Box>
  );
};

export default MarketHeader;
