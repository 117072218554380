import { Box, MenuItem, Select } from "@mui/material";
import { useState } from "react";

import bnbIcon from "../../assets/imgs/bnb.png";
import plsIcon from "../../assets/imgs/pls.png";
import { CHAIN } from "../../config/config";
import { ChainType } from "../../constants/enum/enum";
import useStyles from "./index.styles";

export default function NetworkChangeDropdown() {
  const { classes } = useStyles();
  const [selectedChain, setSelectedChain] = useState(
    CHAIN === ChainType.BNB ? 1 : 2
  );

  return (
    <Box className={classes.select}>
      <Select
        value={selectedChain}
        displayEmpty
        inputProps={{ id: "uncontrolled-select" }}
        MenuProps={{
          sx: {
            "& .MuiPaper-root": {
              backgroundColor: "#161616",
              color: "white",
              "& li": {
                justifyContent: "center",
              },
            },
          },
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
            outline: "none",
          },
          "&.Mui-focused": {
            outline: "none",
          },
        }}
      >
        <MenuItem
          value={1}
          onClick={() => {
            window.location.href = "https://bnbmafia.io";
          }}
        >
          <Box
            component="img"
            src={bnbIcon}
            alt="BNB"
            sx={{ width: 24, height: 24 }}
          />
        </MenuItem>
        <MenuItem
          value={2}
          onClick={() => {
            window.location.href = "https://pulsemafia.io";
          }}
        >
          <Box
            component="img"
            src={plsIcon}
            alt="PLS"
            sx={{ width: 24, height: 24 }}
          />
        </MenuItem>
      </Select>
    </Box>
  );
}
