import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, InputAdornment, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../Logo";
import NativeIcon from "../NativeIcon";

import { menLinksSmall, womenLinksSmall } from "../../constants/avatarLinks";
import { getInventoryItemInfo } from "../../helper/inventory";
import { convertDateTime, toastError, toUSDFormat } from "../../utils/utils";

import clockIcon from "../../assets/imgs/marketplace/clock.png";

import { useAppSelector } from "../../app/hooks";
import config, { SERVER_URL } from "../../config/config";
import useCurrentTime from "../../hook/useCurrentTime";
import { MarketAuctionBidInfo } from "../../types/MarketAuctionBidInfo";
import { MarketListedItem } from "../../types/MarketListedItem";

import useStyles from "./index.styles";
import { Errors } from "../../constants/errors";

interface MarketAuctionPopupProps {
  purchasingItem: MarketListedItem | undefined;
  openMarketAuctionPopup: boolean;
  setOpenMarketAuctionPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTokenListPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setNewPrice: React.Dispatch<React.SetStateAction<number>>;
  showingBids: boolean;
}

const MarketAuctionPopup: React.FC<MarketAuctionPopupProps> = ({
  purchasingItem,
  openMarketAuctionPopup,
  setOpenMarketAuctionPopup,
  setOpenTokenListPopup,
  setNewPrice,
  showingBids,
}) => {
  const { classes } = useStyles();
  const currentTime = useCurrentTime();

  const [usdAmount, setUsdAmount] = useState<string>("");
  const [bidHistory, setBidHistory] = useState<MarketAuctionBidInfo[]>([]);

  const profileNames = useAppSelector((state) => state.profile.profileNames);

  const handleClose = () => {
    setOpenMarketAuctionPopup(false);
  };

  const handleUsdAmountChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (value === "" || /^(\d+(\.\d{0,5})?|\.?\d{1,2})$/.test(value)) {
      setUsdAmount(value);

      setNewPrice(parseFloat(value));
    }
  };

  const handleBidClicked = () => {
    if (!purchasingItem) return;

    if (
      !usdAmount ||
      parseFloat(usdAmount) <= purchasingItem?.listingInfo.currentPrice
    ) {
      toastError(Errors.MARKET.BASIC.NEXT_BID_AMOUNT_INVALID);
      return;
    }

    setOpenTokenListPopup(true);
  };

  const item = getInventoryItemInfo(
    purchasingItem?.categoryId || 0,
    purchasingItem?.typeId || 0,
    purchasingItem?.cityId
  );

  useEffect(() => {
    if (!purchasingItem) return;

    const fetchBidHistory = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/market/bids/${purchasingItem?.listingId}`
        );

        const bidHistory: MarketAuctionBidInfo[] = response.data.map(
          (bid: any) => ({
            gender: bid.User.gender,
            imageId: bid.User.imageId,
            address: bid.buyer,
            price: bid.price,
          })
        );

        setBidHistory(bidHistory);
      } catch (error) {
        console.log("Failed to fetch bid history:", error);
        setBidHistory([]); // Optional: Reset bid history on error
      }
    };

    fetchBidHistory();
  }, [purchasingItem]);

  return (
    <Dialog
      open={openMarketAuctionPopup}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box className={classes.title}>
            <Box component="img" src={item.itemIcon} sx={{ width: 48 }}></Box>
            <Box>{item.itemName}</Box>
          </Box>

          <Box className={classes.expireDescription}>
            <Box component="img" src={clockIcon} sx={{ width: 16 }}></Box>
            Expires in:{" "}
            {convertDateTime(
              (purchasingItem?.listingInfo.expiresAt || 0) - currentTime
            )}
          </Box>

          {!showingBids && (
            <Box className={classes.bidSection}>
              <Box className={classes.bidInfo}>
                <Box className={classes.bidInfoLabel}>Current bid:</Box>

                <TextField
                  placeholder="Current bid"
                  variant="standard"
                  className={classes.inputBox}
                  disabled
                  InputProps={{
                    disableUnderline: true, // Disable the underline
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ color: "white!important" }}
                        sx={{
                          "& p": {
                            color: "white",
                          },
                        }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                  value={purchasingItem?.listingInfo.currentPrice}
                  onChange={handleUsdAmountChange}
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true, // This helps manage the label positioning
                  }}
                  sx={{
                    input: {
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                  }}
                ></TextField>

                <Box className={classes.bidFeeInfo}>
                  {purchasingItem?.listingInfo.token === config.mafiaAddress ? (
                    <>
                      <Logo sx={{ width: 24 }} />
                      <Box>0% fee</Box>
                    </>
                  ) : (
                    <>
                      <NativeIcon sx={{ width: 24 }} />
                      <Box>20% fee</Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box className={classes.bidInfo}>
                <Box className={classes.bidInfoLabel}>Next bid:</Box>

                <TextField
                  placeholder="USD Amount"
                  variant="standard"
                  className={classes.inputBox}
                  InputProps={{
                    disableUnderline: true, // Disable the underline
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ color: "white!important" }}
                        sx={{
                          "& p": {
                            color: "white",
                          },
                        }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                  value={usdAmount}
                  onChange={handleUsdAmountChange}
                  autoComplete="off"
                  sx={{
                    input: {
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                  }}
                ></TextField>

                <Button
                  className={classes.bitButton}
                  onClick={handleBidClicked}
                >
                  Bid
                </Button>
              </Box>
            </Box>
          )}

          <Box className={classes.historySection}>
            <Box>Bid history</Box>

            <Box className={classes.bidHistoryList}>
              {[...[...bidHistory].reverse()].slice(0, 3).map((bid, index) => {
                const profile = profileNames.find(
                  (profile, index) =>
                    profile.address?.toLowerCase() === bid.address.toLowerCase()
                );

                return (
                  <Box className={classes.bidHistory} key={index}>
                    <Box
                      component="img"
                      src={
                        bid.gender === 0
                          ? menLinksSmall[bid.imageId || 0]
                          : womenLinksSmall[bid.imageId || 0]
                      }
                      className={classes.avatar}
                    ></Box>
                    <Link
                      to={`/profile/${profile?.name}`}
                      className={classes.link}
                    >
                      <Box>{profile?.name}</Box>
                    </Link>
                    <Box>${toUSDFormat(bid.price)}</Box>
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box className={classes.feeDescription}>
            100 % of the marketplace fees goes to the vault
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MarketAuctionPopup;
