import { Box } from "@mui/material";
import {
  createChart,
  LastPriceAnimationMode,
  LineData,
  SeriesMarker,
  Time,
} from "lightweight-charts";
import { useEffect } from "react";

import { useAppSelector } from "../../app/hooks";
import { toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";

const MarketItemDetailChart = ({ sx }: { sx?: any }) => {
  const { classes } = useStyles();

  const { itemDetail, isLoadingItemDetail } = useAppSelector(
    (state) => state.market
  );

  useEffect(() => {
    if (isLoadingItemDetail || itemDetail.soldItems.length === 0) return;

    const data = [...itemDetail.soldItems].sort((item1, item2) => {
      return item1.listingInfo.timestamp - item2.listingInfo.timestamp;
    });

    const newChartElement = document.createElement("div");
    newChartElement.style.cssText =
      "width: 100%; height: 100%; min-height: 350px; position:relative";

    const chartContainer = document.getElementById("chart-container");
    if (!chartContainer) return;
    chartContainer.appendChild(newChartElement);

    const chart = createChart(newChartElement, {
      layout: {
        background: { color: "#171a1b" },
        textColor: "#DDD",
      },
      grid: {
        vertLines: { color: "#44444475" },
        horzLines: { color: "#44444475" },
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.1,
          bottom: 0,
        },
        borderVisible: false,
      },
      timeScale: {
        secondsVisible: true,
        tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
          const date = new Date(parseInt(time) * 1000);
          return `${(date.getMonth() + 1).toString().padStart(2, "0")}.${date
            .getDate()
            .toString()
            .padStart(2, "0")}`;
        },
      },
    });

    const series = chart.addAreaSeries({
      lineColor: "#e0a119",
      lastPriceAnimation: LastPriceAnimationMode.Continuous,
      priceLineVisible: false,
      lineWidth: 4,
      lineType: 2,
      lastValueVisible: false,
      priceFormat: {
        type: "custom",
        minMove: 1,
        formatter: (price: number) => {
          return "$" + price.toFixed(1);
        },
      },
    });

    let usd = 0;
    let startSeries = false;
    let seriesData: LineData<Time>[] = [];
    [...data]
      .sort(
        (dataA, dataB) =>
          (dataA.listingInfo.timestamp || 0) -
          (dataB.listingInfo.timestamp || 0)
      )
      .forEach((item, index) => {
        usd += item.listingInfo.currentPrice || 0;
        if (usd > 0 && !startSeries) {
          startSeries = true;
        }
        if (startSeries) {
          if (
            seriesData.findIndex(
              (data, index) =>
                data.time === ((item.listingInfo.timestamp || 0) as Time)
            ) === -1
          )
            seriesData.push({
              time: (item.listingInfo.timestamp || 0) as Time,
              value: item.listingInfo.currentPrice || 0,
            });
        }
      });

    series.setData(seriesData);

    const step =
      Math.floor(data.length / 20) === 0 ? 1 : Math.floor(data.length / 20);
    const markers: SeriesMarker<Time>[] = [];
    seriesData.forEach((point, index) => {
      if (index === seriesData.length - 1) {
        markers.push({
          time: point.time,
          position: "inBar",
          color: "#e0a119",
          shape: "circle",
          size: 0.5,
          text: `$${toUSDFormat(point.value, 1)}`,
        });
      } else if (index % step === 0) {
        markers.push({
          time: point.time,
          position: "inBar",
          color: "#e0a119",
          shape: "circle",
          size: 0.5,
          text: `$${toUSDFormat(point.value, 1)}`,
        });
      }
    });

    series.setMarkers(markers);

    const startTime = Number(data[0].listingInfo.timestamp);
    const endTime = Number(data[data.length - 1].listingInfo.timestamp);
    const timeRange = endTime - startTime;
    const padding = timeRange * 0.1;
    chart.timeScale().setVisibleRange({
      from: (startTime - padding) as Time,
      to: (endTime + padding) as Time,
    });

    return () => {
      if (chartContainer && newChartElement) {
        chartContainer.removeChild(newChartElement);
      }
    };
  }, [isLoadingItemDetail]);

  return (
    <Box className={classes.chartBody} sx={{ ...sx }}>
      <Box className={classes.body} id={"chart-container"}></Box>
      <Box className={classes.totUsd}></Box>
      <Box className={classes.chartTitle}>Item Price Chart</Box>
      <Box className={classes.dateLabel}>Date</Box>
      <Box className={classes.valueLabel}>Price($)</Box>
    </Box>
  );
};

export default MarketItemDetailChart;
