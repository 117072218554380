import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    fontFamily: "Philosopher",
    justifyContent: "center",

    backgroundSize: "auto 100%",
    backgroundPosition: "100%",

    maxWidth: "1920px",
    margin: "auto",
    boxSizing: "border-box",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      padding: "16px 16px",
    },
  },

  container: {
    gap: "48px",
    display: "flex",
    width: 920,
    margin: "auto",
    marginBottom: 16,
    marginTop: 16,

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginBottom: 16,
      marginTop: 16,
      width: "100%",
    },
    position: "relative",
  },

  inventoryCard: {
    width: "100%",
    minHeight: "420px",

    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    backgroundColor: theme.palette.background.cardBgTransparent,
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    height: "360px",
    padding: "24px 32px",
    gap: "32px",
    justifyContent: "flex-start",
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

    boxSizing: "border-box",

    [theme.breakpoints.down("md")]: {
      padding: "16px 16px",
    },

    [theme.breakpoints.down("sm")]: {
      gap: 12,
    },
  },

  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  cardHeaderTitle: {
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontSize: 20,
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  cardHeaderWorth: {
    color: theme.palette.text.secondary,
    fontFamily: "Philosopher",
    fontSize: 14,
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: 14,
    },
  },

  cardList: {
    display: "grid",
    gap: 16,
    padding: 8,
    gridTemplateColumns: "repeat(auto-fill, minmax(66px, 1fr))",
    overflowY: "scroll",
  },

  inventoryItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 8,
  },

  inventoryItemBox: {
    // padding: "4px 8px",
    borderRadius: 8,
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,

    background: theme.palette.background.cardBgTransparent,
    boxShadow:
      "inset 0 -3px 10px 0px rgb(0 0 0 / 15%), inset 3px 0 10px 0px rgb(0 0 0 / 15%), inset 0 3px 10px 0px rgb(0 0 0 / 15%), inset -3px 0 10px 0px rgb(0 0 0 / 15%)",

    transition: "300ms",
    cursor: "pointer",

    "&:hover": {
      filter: "brightness(1.5)",
      transform: "scale(1.2)",
    },
    height: 60,
    clipPath: "border-box",
  },

  inventoryItemIcon: {
    width: 60,
    height: 60,
  },

  inventoryCrateIcon: {},

  inventoryOGIcon: {
    height: 60,
  },

  inventoryMafiaIcon: {
    padding: 8,
    boxSizing: "border-box",
  },

  inventoryItemName: {
    color: theme.palette.text.secondary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 12,

    userSelect: "none",
    textAlign: "center",
  },

  cardHeaderBadgeDanger: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    fontSize: 12,
    color: "#b52c4f",
    fontWeight: 900,
  },

  cardHeaderBadgeSafe: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    fontSize: 12,
    color: "#50b828",
    fontWeight: 900,
  },

  private: {
    filter: "blur(15px)",
    pointerEvents: "none",
  },
}));

export default styles;
