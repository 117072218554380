import { Box, Button, Typography } from "@mui/material";
import { useMemo, useState } from "react";

import CustomComboBox from "./CustomComboBox";

import { useAppDispatch } from "../../app/hooks";
import useCurrentTime from "../../hook/useCurrentTime";

import { PREMIUM_KEY_CLAIM_CYCLE } from "../../constants/const";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import {
  claimKeysAction,
  getCrateBalance,
  getUserPremiumInfo,
  signData,
  updateProfileDisplaySetting,
} from "../../reducers/profile.slice";
import { UserInfo } from "../../types/Contract/Premium/UserInfo";
import { UserProfile } from "../../types/UserProfile";
import {
  convertDateExtremeSimple,
  toastError,
  toastSuccess,
} from "../../utils/utils";

import keyIcon from "../../assets/imgs/inventory/key.png";

import useStyles from "./index.styles";

// Enum to handle visibility settings more clearly
enum Visibility {
  Public,
  Family,
  Private,
}

const ProfileSetting = ({
  profile,
  premiumInfo,
}: {
  profile: UserProfile;
  premiumInfo: { userInfo: UserInfo; lastClaimedAt: number };
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const currentTime = useCurrentTime();

  const [visibility, setVisibility] = useState<Visibility>(
    profile.inventoryVisibility || 0
  );

  const handleVisibilityChange = (newVisibility: Visibility) => {
    const signMsg = {
      address: profile.address,
      displaySetting: newVisibility,
    };
    dispatch(signData({ signMsg }))
      .unwrap()
      .then((signature) => {
        if (profile.id)
          dispatch(
            updateProfileDisplaySetting({
              signMsg,
              signature,
              userId: profile.id,
            })
          )
            .unwrap()
            .then(() => {
              toastSuccess(
                Messages.GLOBAL.PROFILE.INVENTORY_DISPLAY_SETTING_SUCCESS
              );

              setVisibility(newVisibility);
            })
            .catch(() => {
              toastError(
                Errors.GLOBAL.PROFILE.INVENTORY_DISPLAY_SETTING_FAILED
              );
            });
      })
      .catch(() => {
        toastError(Errors.GLOBAL.WEB3.REJECTED);
      });
  };

  const handleClaimKey = () => {
    if (!profile.address) return;

    dispatchTxAction(
      dispatch,
      claimKeysAction({ account: profile.address }),
      () => {
        toastSuccess(Messages.GLOBAL.PROFILE.CLAIMED_KEY_SUCCESS);

        dispatch(getCrateBalance({ account: profile.address || "" }));
        dispatch(getUserPremiumInfo({ account: profile.address || "" }));
      }
    );
  };

  const claimableKeys = useMemo(() => {
    if (
      premiumInfo.userInfo.startedAt === 0 ||
      premiumInfo.userInfo.expireAt < currentTime
    )
      return 0;

    let lastClaimedAt = premiumInfo.lastClaimedAt;
    let claimableUntil = currentTime;
    if (
      premiumInfo.userInfo.startedAt - PREMIUM_KEY_CLAIM_CYCLE >
      lastClaimedAt
    ) {
      lastClaimedAt = premiumInfo.userInfo.startedAt - PREMIUM_KEY_CLAIM_CYCLE;
    }

    if (
      claimableUntil >
      premiumInfo.userInfo.expireAt - PREMIUM_KEY_CLAIM_CYCLE
    ) {
      claimableUntil = premiumInfo.userInfo.expireAt - PREMIUM_KEY_CLAIM_CYCLE;
    }

    const claimTimes = Math.floor(
      (claimableUntil - lastClaimedAt) / PREMIUM_KEY_CLAIM_CYCLE
    );

    const keyAmount = claimTimes * 2;

    return keyAmount;
  }, [currentTime, premiumInfo]);

  const remainTime = useMemo(() => {
    const remainTime =
      (PREMIUM_KEY_CLAIM_CYCLE * 3 -
        (currentTime - premiumInfo.userInfo.startedAt)) %
      PREMIUM_KEY_CLAIM_CYCLE;

    return remainTime;
  }, [currentTime, premiumInfo]);

  return (
    <Box className={classes.body}>
      <Box className={classes.container}>
        <Box
          className={classes.card}
          sx={{
            opacity: profile.premium === 1 ? 1 : 0.3,
          }}
        >
          <Typography variant="h6" className={classes.cardHeaderTitle}>
            Setting
          </Typography>
          <Box className={classes.cardContent}>
            <Box className={classes.settingOptionList}>
              {[Visibility.Public, Visibility.Family, Visibility.Private].map(
                (type) => (
                  <CustomComboBox
                    key={type}
                    disabled={profile.premium !== 1}
                    label={`Inventory ${Visibility[type]}`}
                    checked={visibility === type}
                    handleChange={() => handleVisibilityChange(type)}
                  />
                )
              )}
            </Box>

            {profile.premium === 1 && (
              <Box className={classes.claimKey}>
                <Typography>
                  Next Claim: {convertDateExtremeSimple(remainTime)}
                </Typography>

                <Button
                  className={classes.claimButton}
                  disabled={claimableKeys === 0}
                  startIcon={
                    <Box
                      component="img"
                      src={keyIcon}
                      sx={{
                        width: 20,
                        height: 20,
                      }}
                    />
                  }
                  onClick={handleClaimKey}
                >
                  Claim Keys ({claimableKeys})
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileSetting;
