import { Box } from "@mui/material";
import { ReactNode } from "react";

import InventoryItemIcon from "../InventoryItemIcon";
import Logo from "../Logo";
import NativeIcon from "../NativeIcon";

import { Market } from "../../constants/enum/market";
import { getInventoryItemName } from "../../helper/inventory";
import { toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";

const ActiveListingItem = ({
  item,
  currentTime,
  renderActionButton,
}: {
  item: any;
  currentTime: number;
  renderActionButton: (item: any) => ReactNode;
}) => {
  const { classes } = useStyles();

  const itemName = getInventoryItemName(
    item.categoryId,
    item.typeId,
    item.cityId
  );

  return (
    <Box className={classes.listingPanelItem}>
      <Box>
        <InventoryItemIcon
          categoryId={item.categoryId}
          typeId={item.typeId}
          className={classes.listingItemIcon}
        />
        {itemName}
      </Box>

      <Box>
        {item.listingInfo.listingType === Market.ListType.FIXED
          ? "Fixed"
          : "Auction"}
      </Box>

      <Box>${toUSDFormat(item.listingInfo.currentPrice)}</Box>

      {item.currency === "MAFIA" ? (
        <Logo sx={{ width: 24 }} />
      ) : (
        <NativeIcon sx={{ width: 24 }} />
      )}

      <Box>
        {renderActionButton(item)}
        {/* {item.listingInfo.expiresAt - currentTime < 0 ? (
          renderActionButton(item)
        ) : (
          <Box className={classes.listingRemainTimeNormal}>
            {convertDateTime(item.listingInfo.expiresAt - currentTime)}
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default ActiveListingItem;
