import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import Logo from "../../components/Logo";
import config, { CHAIN } from "../../config/config";
import { NavBarPages } from "../../constants/const";
import { ChainType } from "../../constants/enum/enum";
import useWallet from "../../hook/useWallet";
import { useStyles } from "./index.styles";
import useProfile from "../../hook/useProfile";
import { shortAddress } from "../../utils/utils";

const SideBar = ({
  toggleSideBar,
  onConnectWallet,
}: {
  toggleSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  onConnectWallet: () => void;
}) => {
  const { classes } = useStyles();
  const { disconnectWallet, account, isConnected } = useWallet();
  const { profileName } = useProfile(account);

  const NewBadge = () => {
    return <Box className={classes.badge}>NEW</Box>;
  };

  return (
    <Box
      className={classes.sidebar}
      role="presentation"
      onClick={() => toggleSideBar(false)}
    >
      <List
        subheader={
          <ListSubheader
            className={classes.sidebarHeader}
            component="div"
            id="nested-list-subheader"
          >
            <Logo classNames={classes.sidebarLogo} />
            <NavLink to="/"> {config.title}</NavLink>
          </ListSubheader>
        }
      >
        {NavBarPages.map(
          (item, index) =>
            (!item.plsDisabled ||
              (item.plsDisabled && CHAIN === ChainType.BNB)) && (
              <ListItem key={item.path}>
                <ListItemButton>
                  <NavLink className={classes.sidebarNavLink} to={item.path}>
                    <Box className={classes.mobileSidebarList}>
                      {item.title}
                      {item.new && <NewBadge />}
                    </Box>
                  </NavLink>
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
      <Divider />
      <List>
        {isConnected && (
          <ListItem>
            <ListItemButton className={classes.sidebarNavLink}>
              <NavLink
                className={classes.sidebarNavLink}
                to={`/profile/${profileName}`}
              >
                <Typography component="span" sx={{ fontWeight: 700 }}>
                  {shortAddress(account)}
                </Typography>
                <Box
                  component="img"
                  width={18}
                  sx={{ marginLeft: "8px" }}
                  src="/assets/imgs/layout/user.png"
                ></Box>
              </NavLink>
            </ListItemButton>
          </ListItem>
        )}

        <ListItem>
          <ListItemButton
            className={classes.sideBarConnectWalletBtn}
            onClick={() =>
              isConnected ? disconnectWallet() : onConnectWallet()
            }
          >
            <Typography component="span" sx={{ fontWeight: 700 }}>
              {isConnected ? "Disconnect" : "Connect Wallet"}
            </Typography>
            {isConnected && (
              <Box
                component="img"
                width={18}
                sx={{ marginLeft: "8px" }}
                src="/assets/imgs/layout/power-off.png"
              ></Box>
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default SideBar;
