import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button } from "@mui/material";
import { findFlagUrlByCountryName } from "country-flags-svg-v2";
import { useConfirm } from "material-ui-confirm";
import millify from "millify";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CapoDropDown from "../CustomDropdown/CapoDropDown";
import NativeIcon from "../NativeIcon";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import { menLinksSmall, womenLinksSmall } from "../../constants/avatarLinks";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";

import { FamilyRole } from "../../constants/enum/enum";
import { Capo } from "../../types/Capo";
import { FamilyDetail } from "../../types/FamilyDetails";
import { FamilyMember } from "../../types/FamilyMember";
import { UserProfile } from "../../types/UserProfile";
import {
  calculateFamilyPayout,
  getShortCountryName,
  getWealthCategory,
  toastInfo,
  toUSDFormat,
} from "../../utils/utils";

import hatIcon from "../../assets/imgs/family/hat.png";
import femaleIcon from "../../assets/imgs/landing/female.png";
import maleIcon from "../../assets/imgs/landing/male.png";
import goldenPlus from "../../assets/imgs/profile/GoldenPlus.png";

import { dispatchTxAction } from "../../helper/dispatchTxAction";

import {
  getFamilyDetailByName,
  kickMemberAction,
} from "../../reducers/family.slice";

import useStyles from "./index.styles";

const FamilyDetailMemberListItem = ({
  member,
  role,
  myRole,
  familyDetail,
  myProfile,
  familyPayout,
}: {
  member: FamilyMember;
  role?: FamilyRole;
  myRole?: FamilyRole;
  familyDetail: FamilyDetail;
  myProfile: UserProfile;
  familyPayout: number;
}) => {
  const { classes } = useStyles();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const { familyName } = useParams();

  const handleOpenConfirmModal = () => {
    confirm({
      description: `Do you wanna set remove ${member.name} from family?`,
      dialogProps: {
        PaperProps: {
          sx: { backgroundColor: "#171a1b", color: "white" },
          className: classes.paper,
        },
      },
    })
      .then(() => {
        if (!myProfile.address || !familyDetail.id || !member.address) return;

        dispatchTxAction(
          dispatch,
          kickMemberAction({
            account: myProfile.address,
            familyId: familyDetail.id,
            memberAddress: member.address,
          }),
          () => {
            toastInfo(Messages.FAMILY.BASIC.KICKED, {
              name: member.name || "",
            });
            dispatch(getFamilyDetailByName(familyName || ""));
          },
          0,
          Errors.FAMILY.VALIDATE.KICKING_FAILED
        );
      })
      .catch(() => {});
  };

  return (
    <Box className={classes.listItem}>
      {member.premium === 1 ? (
        <Box className={classes.goldenText}>
          {toUSDFormat(member.worth?.toFixed(0))}
        </Box>
      ) : (
        <Box>{toUSDFormat(member.worth?.toFixed(0))}</Box>
      )}
      <Box>
        <Box
          component="img"
          src={
            member.gender === 0
              ? menLinksSmall[member.imageId || 0]
              : womenLinksSmall[member.imageId || 0]
          }
          className={classes.avatar}
        ></Box>
      </Box>
      <Box>
        <Link to={`/profile/${member.name}`}>{member.name}</Link>

        {member.premium === 1 && (
          <Box
            component="img"
            src={goldenPlus}
            className={classes.goldenPlus}
          ></Box>
        )}
      </Box>
      <Box
        className={
          role !== undefined && role < FamilyRole.Capo1
            ? classes.leader
            : role === FamilyRole.Capo1
            ? classes.capo
            : ""
        }
      >
        {role !== undefined
          ? role === FamilyRole.Capo1
            ? "Capo"
            : FamilyRole[role]
          : ""}
      </Box>
      <Box className={classes.genderItem}>
        <Box>{member.gender === 0 ? "Male" : "Female"}</Box>
        <Box
          component="img"
          src={member.gender === 0 ? maleIcon : femaleIcon}
          className={classes.gender}
        ></Box>
      </Box>

      <Box>
        {getWealthCategory((member.mafia || 0) + (member.stMafia || 0))}
      </Box>
      <Box>
        <Box
          component="img"
          src={findFlagUrlByCountryName(member.country || "United States")}
          alt=""
          srcSet=""
          className={classes.flag}
        />
        {getShortCountryName(member.country || "")}
      </Box>
      {role === FamilyRole.Member ? (
        <Box>
          <CapoDropDown
            disabled={myRole === FamilyRole.Member}
            value={
              familyDetail.capoRegime.find(
                (capo, index) => capo.capoId === member.capo
              )?.capoBoss || "None"
            }
            sx={{ maxWidth: "150px" }}
            address={member.address || ""}
            capoRegime={familyDetail.capoRegime}
            items={[
              "None",
              ...familyDetail.capoRegime.map(
                (capo, index) => capo.capoBoss || ""
              ),
            ]}
          />
        </Box>
      ) : (
        <Box></Box>
      )}

      <Box className={classes.payout}>
        <NativeIcon sx={{ width: 16 }} />
        <Box>
          {millify(familyPayout * (member.payoutPercent || 0), {
            precision: 2,
          })}{" "}
          {config.chainSymbol}
        </Box>
      </Box>
      <Box>{toUSDFormat(member.stMafia)}</Box>
      <Box>{toUSDFormat(member.mafia)}</Box>

      <Link
        to={`${config.explorerUrl}address/${member.address}`}
        target="_blank"
      >
        <Box>View</Box>
      </Link>

      <Button
        onClick={handleOpenConfirmModal}
        disabled={
          myRole === FamilyRole.Member ||
          role === myRole ||
          myRole === FamilyRole.Capo1 ||
          myRole === FamilyRole.Capo2 ||
          myRole === FamilyRole.Capo3 ||
          myRole === FamilyRole.Capo4 ||
          myRole === FamilyRole.Capo5
        }
      >
        <ClearIcon color="error"></ClearIcon>
      </Button>
    </Box>
  );
};

const FamilyDetailMemberList = ({ vaultBalance }: { vaultBalance: number }) => {
  const { classes } = useStyles();
  const { familyDetail } = useAppSelector((state) => state.family);

  const { myProfile } = useAppSelector((state) => state.profile);

  const [role, setRole] = useState<FamilyRole>(FamilyRole.Member);
  const [familyPayout, setFamilyPayout] = useState(0);

  useEffect(() => {
    const getMyRole = () => {
      if (!myProfile.name) return FamilyRole.Member;

      if (familyDetail.don?.name === myProfile.name) return FamilyRole.Don;
      else if (familyDetail.consigliere?.name === myProfile.name)
        return FamilyRole.Consigliere;
      else if (familyDetail.capodecina?.name === myProfile.name)
        return FamilyRole.Capodecina;
      else if (
        familyDetail.capoRegime.find(
          (capo, index) => capo.capoBoss === myProfile.name
        )
      )
        return FamilyRole.Capo1;

      return FamilyRole.Member;
    };

    const role = getMyRole();
    setRole(role);
  }, [myProfile, familyDetail]);

  useEffect(() => {
    if (familyDetail.familyInfo?.rank && familyDetail.familyInfo?.rank <= 8) {
      const familyPayout = calculateFamilyPayout(
        vaultBalance,
        familyDetail.familyInfo?.rank
      );

      setFamilyPayout(familyPayout);
    }
  }, [familyDetail, vaultBalance]);

  return (
    <Box className={classes.container}>
      <Box className={classes.memberListCard}>
        <Box className={classes.memberListCardHeader}>
          <Box component="img" src={hatIcon}></Box>
          <Box>Members list ({familyDetail.members.length})</Box>
        </Box>

        <Box className={classes.memberList}>
          <Box className={classes.listHeader}>
            <Box>Worth</Box>
            <Box>Avatar</Box>
            <Box>Name</Box>
            <Box>Role</Box>
            <Box>Gender</Box>
            <Box>Wealth</Box>
            <Box>Country</Box>
            <Box>Capo</Box>
            <Box>Payout</Box>
            <Box>$stMafia</Box>
            <Box>$MAFIA</Box>
            <Box>Explorer</Box>
            <Box>Action</Box>
          </Box>

          {[...familyDetail.members]
            .sort(
              (member1, member2) => (member2.worth || 0) - (member1.worth || 0)
            )
            .map((member, index) => {
              let roleInFamily;
              if (familyDetail.don?.name === member.name) {
                roleInFamily = FamilyRole.Don;
              } else if (familyDetail.capodecina?.name === member.name) {
                roleInFamily = FamilyRole.Capodecina;
              } else if (familyDetail.consigliere?.name === member.name) {
                roleInFamily = FamilyRole.Consigliere;
              } else if (
                familyDetail.capoRegime.find(
                  (capo: Capo, index) => capo.capoBoss === member.name
                )
              ) {
                roleInFamily = FamilyRole.Capo1;
              } else {
                roleInFamily = FamilyRole.Member;
              }
              return (
                <FamilyDetailMemberListItem
                  role={roleInFamily}
                  myRole={role}
                  member={member}
                  key={index}
                  myProfile={myProfile}
                  familyDetail={familyDetail}
                  familyPayout={familyPayout}
                />
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default FamilyDetailMemberList;
