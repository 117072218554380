import { Box } from "@mui/material";
import clsx from "clsx";
import { Link } from "react-router-dom";

import InventoryItemIcon from "../InventoryItemIcon";

import { useAppSelector } from "../../app/hooks";
import { Market } from "../../constants/enum/market";
import { getInventoryItemName } from "../../helper/inventory";
import { toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";

const CompletedListingItem = ({ item }: { item: any }) => {
  const { classes } = useStyles();
  const profileNames = useAppSelector((state) => state.profile.profileNames);
  const itemName = getInventoryItemName(item.categoryId, item.typeId);

  const buyerProfile = profileNames.find(
    (profile, index) =>
      profile.address?.toLowerCase() === item.listingInfo.buyer.toLowerCase()
  );

  return (
    <Box className={clsx(classes.listingPanelItem, classes.completedPanelItem)}>
      <Box>
        <InventoryItemIcon
          categoryId={item.categoryId}
          typeId={item.typeId}
          className={classes.listingItemIcon}
        />
        {itemName}
      </Box>

      <Box>
        {item.listingInfo.listingType === Market.ListType.FIXED
          ? "Fixed"
          : "Auction"}
      </Box>

      <Box>${toUSDFormat(item.listingInfo.currentPrice)}</Box>

      <Link
        to={`/profile/${buyerProfile?.name}`}
        target="_blank"
        className={classes.link}
      >
        <Box>{buyerProfile?.name}</Box>
      </Link>

      <Box>
        {item.listingInfo.status === 1 ? (
          <Box className={classes.listingRemainTimeNormal}>Completed</Box>
        ) : (
          <Box className={classes.listingRemainTimeCritical}>Expired</Box>
        )}
      </Box>
    </Box>
  );
};

export default CompletedListingItem;
