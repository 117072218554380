import { Box, Typography } from "@mui/material";
import millify from "millify";

import { ItemCategoryInfoList } from "../../constants/const";
import { ItemCategoryInfo } from "../../types/ItemCategoryInfo";
import { toUSDFormat } from "../../utils/utils";

import { useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import { ItemCategory } from "../../constants/enum/enum";
import useStyles from "./index.styles";
import useMafiaBalance from "../../hook/useInventoryBalance";
import config from "../../config/config";

const CratePrizeListItem = ({
  category,
  stocks,
  inventoryBalance,
}: {
  category: ItemCategoryInfo;
  stocks: number;
  inventoryBalance?: number;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.prize}>
      <Box className={classes.prizeInfo}>
        <Box
          component="img"
          src={`/assets/imgs/inventory/${category.icon}`}
          sx={{
            width:
              category.id === ItemCategory.OGNFT
                ? "24px!important"
                : "32px!important",
            height:
              category.id === ItemCategory.OGNFT
                ? "24px!important"
                : "32px!important",
          }}
        ></Box>
        <Typography>{category.name}</Typography>
      </Box>

      <Box className={classes.prizeCard}>
        {category.values.map((value, index) => {
          return (
            <Box key={index}>
              {typeof value === "number"
                ? category.currency
                  ? `${category.currency} ${toUSDFormat(value)}`
                  : `${toUSDFormat(value)}`
                : value}
            </Box>
          );
        })}
      </Box>
      {category.id === ItemCategory.BUSINESS && (
        <Box className={classes.stock}>({stocks} Remaining)</Box>
      )}

      {category.id === ItemCategory.MAFIA && (
        <Box className={classes.stock}>
          ({millify(inventoryBalance || 0)} $MAFIA Remaining)
        </Box>
      )}
    </Box>
  );
};

const ITEM_CATEGORY_COUNT = 2;

const CrateOpeningPrizeList = ({
  businessAvailable,
}: {
  businessAvailable: boolean;
}) => {
  const { classes } = useStyles();

  const inventoryBalance = useMafiaBalance(config.inventoryAddress);
  const { inventoryItemStocks } = useAppSelector((state) => state.profile);

  const filteredItemCategoryList = useMemo(() => {
    if (businessAvailable) {
      return ItemCategoryInfoList.filter((category, index) => {
        return category.id !== ItemCategory.KEY;
      });
    } else {
      return ItemCategoryInfoList.filter((category, index) => {
        return (
          category.id !== ItemCategory.KEY && index !== ItemCategory.BUSINESS
        );
      });
    }
  }, [businessAvailable]);

  return (
    <Box className={classes.container}>
      <Box className={classes.prizeList}>
        {filteredItemCategoryList
          .slice(
            0,
            businessAvailable
              ? ItemCategory.BUSINESS + 1
              : ItemCategory.BUSINESS
          )
          .map((itemCategory, index) => {
            return (
              <CratePrizeListItem
                category={itemCategory}
                stocks={inventoryItemStocks[index]}
                key={index}
              />
            );
          })}

        {filteredItemCategoryList
          .slice(
            filteredItemCategoryList.length - ITEM_CATEGORY_COUNT,
            filteredItemCategoryList.length
          )
          .map((itemCategory, index) => {
            return (
              <CratePrizeListItem
                category={itemCategory}
                stocks={inventoryItemStocks[index]}
                key={index}
                inventoryBalance={inventoryBalance}
              />
            );
          })}

        {filteredItemCategoryList
          .slice(
            businessAvailable
              ? ItemCategory.BUSINESS + 1
              : ItemCategory.BUSINESS,
            filteredItemCategoryList.length - ITEM_CATEGORY_COUNT
          )
          .map((itemCategory, index) => {
            return (
              <CratePrizeListItem
                category={itemCategory}
                stocks={inventoryItemStocks[index]}
                key={index}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default CrateOpeningPrizeList;
