import isMobile from "is-mobile";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Web3 from "web3";

import MenuIcon from "@mui/icons-material/Menu";
import { Badge, Drawer } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ToastContainer } from "react-toastify";

import AllegianceInvitationPopup from "../../components/AllegianceInvitationPopup";
import BackOverlay from "../../components/BackOverlay";
import ChatPanel from "../../components/ChatPanel";
import FamilyInvitationMemberPopup from "../../components/FamilyInvitationMemberPopup";
import Logo from "../../components/Logo";
import NetworkChangeDropdown from "../../components/NetworkChangeDropdown";
import RateLimitPopup from "../../components/RateLimitPopup";
import NotificationPanel from "./NotificationPanel";
import SideBar from "./SideBar";

import {
  activityTracking,
  clearAuth,
  requestAuthorize,
  setAuth,
} from "../../reducers/auth.slice";
import { getFamilyList } from "../../reducers/family.slice";
import { clearNotificationBadge } from "../../reducers/notification.slice";
import {
  getCrateBalance,
  getOgNFTBalance,
  getProfileNames,
} from "../../reducers/profile.slice";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config, { CHAIN } from "../../config/config";
import { NavBarPages } from "../../constants/const";
import { ChainType } from "../../constants/enum/enum";
import { switchNetwork } from "../../helper/network";
import { FamilyInvitation } from "../../types/FamilyInvitation";
import { shortAddress } from "../../utils/utils";

import useAuth, { checkSignature } from "../../hook/useAuth";
import useProfile from "../../hook/useProfile";
import useWallet from "../../hook/useWallet";

import { useStyles } from "./index.styles";
import { AllegianceRequestInfo } from "../../types/AllegianceRequestInfo";
import { AllegianceInvitationInfo } from "../../types/AllegianceInvitationInfo";
import AllegianceRequestPopup from "../../components/AllegianceRequestPopup";

const MainLayout = (props: React.PropsWithChildren) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { wallet, connectWallet, disconnectWallet, account, isConnected } =
    useWallet();
  const needToAuth = useAuth(account);
  const { myProfile, profileName } = useProfile(account);

  const { notifications, notificationCount } = useAppSelector(
    (state) => state.notification
  );

  const rateExceeded = useAppSelector((state) => state.auth.rateExceeded);

  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openAllegianceInviteModal, setOpenAllegianceInviteModal] =
    useState(false);
  const [openAllegianceRequestModal, setOpenAllegianceRequestModal] =
    useState(false);

  const [invitationInfo, setInvitationInfo] = useState<FamilyInvitation>();
  const [allegianceInvitationInfo, setAllegianceInvitationInfo] =
    useState<AllegianceInvitationInfo>();

  const [allegianceRequestInfo, setAllegianceRequestInfo] =
    useState<AllegianceRequestInfo>();

  const [anchorElNotification, setAnchorElNotification] =
    useState<null | HTMLElement>(null);

  const [showSidebar, toggleSideBar] = useState<boolean>(false);
  const [showNotification, toggleNotification] = useState<boolean>(false);

  const navbarRef = useRef<React.ElementRef<"div">>(null);
  const currentNavRef = useRef<any>(null);

  const [openChat, setOpenChat] = useState(false);

  const [muted, setMuted] = useState(localStorage.getItem("mute") === "true");

  const handleMouseMove = (e: React.MouseEvent) => {
    const hoverSpan = document.getElementById("navbar-hover-span");
    if (!navbarRef.current || !hoverSpan) return;
    const childs = navbarRef.current.getElementsByTagName("a");

    for (let i = 0; i < childs.length; i++) {
      const rect = childs[i].getClientRects()[0];

      if (rect.left < e.clientX && rect.right > e.clientX) {
        if (currentNavRef.current === childs[i]) return;
        const navbarRect = navbarRef.current.getClientRects()[0];
        const iconElem = childs[i].getElementsByTagName("img")[0];
        const iconRect = iconElem.getClientRects()[0];
        hoverSpan.style.left =
          iconRect.left - navbarRect.left - (25 - iconRect.width) + "px";
        hoverSpan.style.width = rect.width + 50 + "px";
        hoverSpan.style.opacity = "1";
        currentNavRef.current = childs[i];
        return;
      }
    }

    hoverSpan.style.opacity = "0";
  };

  const handleMouseLeave = () => {
    currentNavRef.current = null;
    const hoverSpan = document.getElementById("navbar-hover-span");
    hoverSpan && (hoverSpan.style.opacity = "0");
  };

  const handleShowNotification = (event: React.MouseEvent<HTMLElement>) => {
    toggleNotification((previousOpen) => !previousOpen);
    setAnchorElNotification(event.currentTarget);

    if (notifications.length === 0) return;

    dispatch(clearNotificationBadge());
  };

  const toggleMute = () => {
    const muted = localStorage.getItem("mute") === "true";

    if (muted) {
      localStorage.setItem("mute", "false");
    } else {
      localStorage.setItem("mute", "true");
    }

    setMuted(!muted);

    window.dispatchEvent(
      new CustomEvent("mute", { detail: { key: "mute", newValue: !muted } })
    );
  };

  const onConnectWallet = () => {
    setOpenChat(false);
    connectWallet();
  };

  useEffect(() => {
    if (wallet) {
      const checkNetwork = () => {
        const web3 = new Web3(wallet.provider);
        (window as any).provider = web3;

        (window as any).wallet = wallet;

        if (wallet.chains[0].id !== `0x${config.chainIdHex.toString(16)}`) {
          disconnectWallet();
          switchNetwork(wallet.provider)
            .then(() => {
              onConnectWallet();
            })
            .catch((error) => {
              disconnectWallet();
            });
        }
      };

      checkNetwork();
    }
  }, [wallet, dispatch]);

  useEffect(() => {
    if (!account) {
      dispatch(clearAuth());
      return;
    }

    if (checkSignature(account)) {
      dispatch(requestAuthorize({ account }));
    } else {
      dispatch(setAuth(account));
    }

    dispatch(activityTracking());
  }, [account, dispatch, needToAuth]);

  useEffect(() => {
    dispatch(getCrateBalance({ account: account }));
    dispatch(getOgNFTBalance({ account: account }));
  }, [account, dispatch]);

  useEffect(() => {
    dispatch(getFamilyList());
    dispatch(getProfileNames());
  }, [dispatch, rateExceeded]);

  const NewBadge = () => {
    return <Box className={classes.badge}>NEW</Box>;
  };

  return (
    <Box className={classes.body}>
      <AppBar
        position="static"
        sx={{ background: "none", boxShadow: "none", zIndex: 1 }}
      >
        <Toolbar disableGutters className={classes.toolbar}>
          <Box className={classes.mdLogoWrapper}>
            <Logo
              onClick={() => navigate("/")}
              sx={{
                width: { md: 90, xl: 120 },
                cursor: "pointer",
              }}
            />

            <Box>MAFIA</Box>
          </Box>

          <Box sx={{ flexGrow: 1, display: { md: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => toggleSideBar(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              PaperProps={{ className: classes.sidebarPaper }}
              open={showSidebar}
              onClose={() => toggleSideBar(false)}
            >
              <SideBar
                toggleSideBar={toggleSideBar}
                onConnectWallet={onConnectWallet}
              />
            </Drawer>
          </Box>

          <Logo
            sx={{
              display: { lg: "none", md: "block", sm: "none", xs: "none" },
              width: 48,
            }}
          />

          <Box
            ref={navbarRef}
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                lg: "flex",
                justifyContent: "space-around",
              },
              justifyContent: "center",
              position: "relative",
            }}
            onMouseMove={(e) => handleMouseMove(e)}
            onMouseLeave={() => handleMouseLeave()}
          >
            {NavBarPages.map(
              (page) =>
                (!page.plsDisabled ||
                  (page.plsDisabled && CHAIN === ChainType.BNB)) && (
                  <NavLink
                    key={page.path}
                    to={page.path}
                    className={classes.mdLink}
                    target={page.blank ? "_blank" : undefined}
                  >
                    <Box
                      className={classes.lgNavIcon}
                      component={"img"}
                      src={`/assets/imgs/layout/${page.icon}`}
                    ></Box>
                    {page.title}
                    {page.new && <NewBadge />}
                  </NavLink>
                )
            )}
            <Box
              component="span"
              className={classes.navHoverSpan}
              id="navbar-hover-span"
            />
          </Box>

          <Box className={classes.networkChangeSection}>
            <NetworkChangeDropdown />
          </Box>

          <Box className={classes.chatSection}>
            <Button
              className={classes.chatBtn}
              onClick={() => setOpenChat((open) => !open)}
            >
              <Box
                component={"img"}
                src="/assets/imgs/layout/messenger.png"
                width={21}
                sx={{ cursor: "pointer" }}
              ></Box>
            </Button>
          </Box>

          <Box className={classes.chatSection}>
            <Button className={classes.chatBtn} onClick={toggleMute}>
              <Box
                component={"img"}
                src={
                  muted
                    ? "/assets/imgs/layout/mute.png"
                    : "/assets/imgs/layout/volume.png"
                }
                width={21}
                sx={{ cursor: "pointer" }}
              ></Box>
            </Button>
          </Box>

          <Box className={classes.userSection}>
            {isConnected && (
              <>
                <Button
                  className={classes.notificationBtn}
                  aria-describedby={"transition-popper"}
                  onClick={handleShowNotification}
                >
                  <Badge
                    badgeContent={notificationCount}
                    color="error"
                    sx={{ cursor: "pointer" }}
                  >
                    <Box
                      component="img"
                      src="/assets/imgs/layout/notification.png"
                      width={21}
                    />
                  </Badge>
                </Button>

                <NotificationPanel
                  account={account}
                  showNotification={showNotification}
                  anchorElNotification={anchorElNotification}
                  toggleNotification={toggleNotification}
                  setInvitationInfo={setInvitationInfo}
                  setOpenInviteModal={setOpenInviteModal}
                  setAllegianceInvitationInfo={setAllegianceInvitationInfo}
                  setOpenAllegianceInviteModal={setOpenAllegianceInviteModal}
                  setAllegianceRequestInfo={setAllegianceRequestInfo}
                  setOpenAllegianceRequestModal={setOpenAllegianceRequestModal}
                />
              </>
            )}

            {isConnected && (
              <Box className={classes.menuListBody}>
                <NavLink
                  to={`profile/${profileName}`}
                  className={classes.profileLinkText}
                >
                  My profile
                </NavLink>

                {myProfile.family && myProfile.family > 0 && (
                  <NavLink
                    to={`family/${myProfile.userFamily?.name}`}
                    className={classes.profileLinkText}
                  >
                    My Family
                  </NavLink>
                )}

                <NavLink
                  to={`profile/${profileName}`}
                  className={classes.userProfileBtn}
                >
                  <Tooltip title={<>{account}</> || ""}>
                    <Typography component="span" sx={{ fontWeight: 700 }}>
                      {Object.keys(myProfile).length === 0
                        ? isMobile()
                          ? shortAddress(account, 3, 3)
                          : shortAddress(account)
                        : myProfile.name}
                    </Typography>
                  </Tooltip>

                  <Box
                    component="img"
                    width={18}
                    sx={{ marginLeft: "8px", verticalAlign: "sub" }}
                    src={"/assets/imgs/layout/user.png"}
                  ></Box>
                </NavLink>
              </Box>
            )}

            <Tooltip title={isConnected ? "Disconnect" : "Connect"}>
              <Button
                className={classes.connectWalletBtn}
                onClick={() =>
                  isConnected ? disconnectWallet() : onConnectWallet()
                }
              >
                {isConnected ? (
                  <>
                    <Box
                      component="img"
                      width={24}
                      src="/assets/imgs/layout/power-off.png"
                    ></Box>
                  </>
                ) : isMobile() ? (
                  <>
                    <Box
                      component={"img"}
                      width={24}
                      src="/assets/imgs/layout/wallet.png"
                    />
                  </>
                ) : (
                  <Typography component="span" sx={{ fontWeight: 700 }}>
                    Connect Wallet
                  </Typography>
                )}
              </Button>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>

      <Box>{props.children}</Box>

      <FamilyInvitationMemberPopup
        openInviteModal={openInviteModal}
        setOpenInviteModal={setOpenInviteModal}
        invitationInfo={invitationInfo}
      />

      <AllegianceInvitationPopup
        openInviteModal={openAllegianceInviteModal}
        setOpenInviteModal={setOpenAllegianceInviteModal}
        allegianceInvitationInfo={allegianceInvitationInfo}
      />

      <AllegianceRequestPopup
        openInviteModal={openAllegianceRequestModal}
        setOpenInviteModal={setOpenAllegianceRequestModal}
        allegianceRequestInfo={allegianceRequestInfo}
      />

      <RateLimitPopup openRateLimitPopup={rateExceeded} />

      <BackOverlay />

      <ChatPanel open={openChat} setOpen={(open) => setOpenChat(open)} />

      <ToastContainer />
    </Box>
  );
};

export default MainLayout;
