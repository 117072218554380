import { makeStyles } from "tss-react/mui";

// TODO: Fix all the paddings, margins into using theme.spacing

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },
  },

  modalContent: {
    position: "relative",
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 48,

    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
      minWidth: "auto",
    },

    minWidth: 360,
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },

  formFields: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },

  formInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",

      "&>*": {
        width: "100%!important",
      },
    },
  },

  allegianceItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.background.defaultBorder}`,
    padding: theme.spacing(1),
  },
  requestButton: {
    borderRadius: 8,
    padding: theme.spacing(0.75, 1.5),
    background: "transparent",
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.background.blackBorder}`,
    fontSize: 12,
    fontFamily: "Philosopher",
  },
  createButton: {
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    textTransform: "none",
    minWidth: 160,
    fontSize: 16,
    background: theme.palette.background.greenGradient,
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
    },
  },

  inputNameField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none",
    fontFamily: "Philosopher",
    textTransform: "none",
    fontWeight: 700,
    borderRadius: 8,
    gap: 2,
    padding: theme.spacing(0, 0, 0, 3),
    background: theme.palette.background.lightGray,
    color: theme.palette.text.primary,
    width: 180,

    "&.MuiOutlinedInput-root": {
      border: "none",
    },

    "& svg": {
      color: theme.palette.text.secondary,
    },

    "&:hover": {
      border: "none",
      outline: "none",
    },

    "& input": {
      padding: 0,
      fontFamily: "Philosopher",

      "&::placeholder": {
        color: theme.palette.text.secondary,
        opacity: 1,
      },
    },

    "& fieldset": {
      border: "none",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "0",
      width: "100%",

      "&>div": {
        width: "100%",
        padding: "0 16px 0 24px",
      },
    },
  },

  inputButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none",
    fontFamily: "Philosopher",
    textTransform: "none",
    fontWeight: 700,
    borderRadius: 8,
    gap: 2,
    padding: theme.spacing(1, 3),
    background: theme.palette.background.lightGray,
    color: theme.palette.text.primary,
    width: 140,

    "& svg": {
      color: theme.palette.text.secondary,
    },

    "&:hover": {
      border: "none",
      outline: "none",
    },
  },

  colorPickerButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none",
    fontFamily: "Philosopher",
    textTransform: "none",
    fontWeight: 700,
    borderRadius: 8,
    gap: 2,
    padding: theme.spacing(1, 3),
    background: theme.palette.background.lightGray,
    color: theme.palette.text.primary,
    width: 140,

    "& svg": {
      color: theme.palette.text.secondary,
    },

    "&:hover": {
      border: "none",
      outline: "none",
    },
  },

  colorPicker: {
    position: "absolute",
  },

  colorField: {
    width: 24,
    height: 24,
    borderRadius: 4,
  },

  allegianceList: {
    display: "flex",
    flexDirection: "column",
    minWidth: "300px",
  },
  allegianceListHeader: {
    display: "grid",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,

    gridTemplateColumns: "3fr 1.5fr 1fr 2fr 0.5fr",
    fontSize: 11,
    color: theme.palette.text.secondary,
    justifyItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0),
      gridTemplateColumns: "3fr 1fr 1.5fr 1fr 0.5fr",
    },

    "&>div:first-of-type": {
      width: "100%",
    },
  },
  allegianceListItem: {
    display: "grid",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,

    gridTemplateColumns: "3fr 1.5fr 1fr 2fr 0.5fr",
    justifyItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0),
      gridTemplateColumns: "3fr 1fr 1.5fr 1fr 0.5fr",
    },

    "& button": {
      padding: 0,
    },
  },
  allegianceListFamily: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  allegianceIcon: {
    marginRight: theme.spacing(2),
    width: 18,

    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  manageSection: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  selectFamily: {
    minWidth: 200,
    marginBottom: theme.spacing(2),
  },
  vaultSharing: {
    display: "flex",
    alignItems: "center",
  },
  dissolveButton: {
    marginTop: theme.spacing(4),
  },

  manageOptions: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    width: "100%",
  },

  listOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",

      "&>*": {
        width: "100%!important",
      },

      "& p": {
        fontSize: 14,
        textAlign: "left",
      },
    },
  },

  keyInfos: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.lightGray,
    padding: 16,
    borderRadius: 16,

    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateAreas: '"a b" "c c"',
      gap: 8,
    },

    "& div:nth-of-type(2)": {
      [theme.breakpoints.down("sm")]: {
        order: 2,
        gridArea: "c",

        "&>div": {
          justifyContent: "center",
        },

        "&>button": {
          marginTop: 8,
        },
      },
    },
  },
}));

export default styles;
