import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    padding: "0 16px",
    background: "url(/assets/imgs/family/bg1.png) no-repeat",
    backgroundSize: "100% auto",
    // backgroundPosition: "center",

    maxWidth: 1920,
    margin: "auto",
    minHeight: 720,

    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "top",
    },
  },

  pageHeader: {
    maxWidth: 1440,
    margin: "auto",
    width: "100%",
    fontFamily: "Philosopher",
    paddingTop: 64,
    color: theme.palette.text.primary,
    fontSize: 36,
    fontWeight: 900,
    display: "flex",
    justifyContent: "space-between",

    "& button": {
      background: theme.palette.background.defaultButton,
      color: theme.palette.text.primary,
    },
  },

  familyList: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 1440,
    margin: "auto",
    marginTop: 32,

    backgroundColor: theme.palette.background.cardBgTransparent,
    padding: "16px",
    borderRadius: "8px",

    overflowX: "scroll",

    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

    "& a": {
      textDecoration: "none",
    },
  },

  listHeader: {
    display: "grid",
    fontFamily: "Philosopher",
    gridTemplateColumns: "1fr 1fr 2fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 900,
    alignItems: "center",
    // borderRadius: 4,
    padding: 16,

    minWidth: 1200,

    "&>div": {
      display: "flex",
      alignItems: "center",

      "&:last-of-type": {
        justifyContent: "center",
      },
    },
  },

  clickingOrder: {
    cursor: "pointer",
  },

  listItem: {
    display: "grid",
    fontFamily: "Philosopher",
    gridTemplateColumns: "1fr 1fr 2fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    color: theme.palette.text.secondary,

    alignItems: "center",
    fontSize: 18,
    fontWeight: 900,
    padding: "10px 16px",

    minWidth: 1200,
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    transitionDuration: "300ms",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#ffffff0d",
      color: theme.palette.text.grayColor,
    },

    "& a": {
      color: theme.palette.text.linkColor,
    },

    "& div:last-of-type": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
  },

  link: {
    color: theme.palette.text.primary,
  },

  familyAllegiance: {
    display: "flex",
    gap: 4,

    "& img": {
      width: 24,
      height: 24,
      borderRadius: 4,
    },
  },

  familyName: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.linkColor,

    gap: 8,
  },

  familyLogo: {
    objectFit: "cover",
    width: 24,
    height: 32,
    borderRadius: 4,
  },

  vaultIcon: {
    textAlign: "center",
    margin: "auto",
    "& img": { width: 24, height: 24 },
  },

  pagination: {
    marginTop: 16,
    marginBottom: 32,
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
    "& .MuiPaginationItem-root": {
      borderColor: theme.palette.background.gray,
      color: theme.palette.text.secondary,
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: theme.palette.background.gray,
      color: "white",
    },
  },

  goldenText: {
    background: theme.palette.text.goldenGradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bold",
  },

  goldenPlus: {
    width: "10px!important",
    height: "32px!important",
    objectFit: "contain",
    objectPosition: "top",
  },
}));

export default styles;
