import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  select: {
    "& div": {
      color: "white",
      display: "flex",
    },

    "& svg": {
      color: "white",
    },

    "& li": {
      justifyContent: "center",
    },
  },
}));

export default useStyles;
