import { Box } from "@mui/material";

import useStyles from "./index.styles";

import FamilyList from "../../components/FamilyList";

const FamilyLeaderBoard = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.body}>
      <FamilyList />
    </Box>
  );
};

export default FamilyLeaderBoard;
